import Button from '@mui/material/Button'
import { Link } from 'wouter'

const ButtonLink = ({ title, href, children, ...restProps }) => {
  return (
    <Link href={href}>
      <Button
        type='submit'
        fullWidth
        variant='contained'
        {...restProps}
      >
        {children}
      </Button>
    </Link>
  )
}

export default ButtonLink
