import { useState, useEffect, useCallback, useContext, createContext, useMemo } from 'react'
import useAuth from './useAuth'
import useQueryParams from './useQueryParams'
import useLanguage from './useLanguage'
import Backend from '../common/Backend'
import { isEqual } from 'lodash'

const withoutDefaultParams = (newParams, defaultParams) => {
  return Object.fromEntries(Object.entries(newParams).filter(([key, value]) => {
    return defaultParams[key] !== value
  }))
}

const DocumentsContext = createContext()

export const useDocuments = () => {
  const context = useContext(DocumentsContext)

  if (context === undefined) {
    throw new Error('useDocuments must be used within an DocumentsProvider')
  }

  return context
}

export const DocumentsProvider = ({ children }) => {
  const { session, loading: loadingSession } = useAuth()
  const [queryParams, updateQueryParams] = useQueryParams()
  const { language, resolvedLanguage } = useLanguage()
  const [documents, setDocuments] = useState([])
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState()
  const defaultFilterParams = useMemo(() => {
    return ({
      limit: 60,
      language: resolvedLanguage
    })
  }, [resolvedLanguage])
  const [filterToApply, setFilterToApply] = useState()
  const [loading, setLoading] = useState(false)
  const [contentReady, setContentReady] = useState(true)
  const [error, setError] = useState('')
  const [remainingTagIds, setRemainingTagIds] = useState([])

  const contentReadyTimeout = 5

  const hideContent = () => {
    setContentReady(false)
    setDocuments([])
  }

  // fetch document data
  const loadDocuments = useCallback(async () => {
    if (!session?.user) {
      return
    }
    const { documents, total, tags = [] } = await Backend.getDocuments(session.user.id, session.token, filter)
    setRemainingTagIds(tags.map(({ id }) => id) || [])
    const { documents: newDocuments } = await Backend.getDocuments(session.user.id, session.token, { new: true, language: filter.language })
    const newDocumentIdsMap = newDocuments
      .filter(d => !!d)
      .reduce((acc, d) => {
        acc[d.id] = true
        return acc
      }, {})

    const documentsWithNewAttribute = documents
      .filter(d => !!d)
      .map(d => ({
        ...d,
        isNew: Boolean(newDocumentIdsMap[d.id])
      }))

    setTotal(total)
    setTimeout(() => {
      setDocuments(documentsWithNewAttribute)
      setContentReady(true)
    }, contentReadyTimeout)
  }, [session, filter])

  // handle document data refreshing
  const refresh = useCallback(async () => {
    setLoading(true)
    if (loadingSession) {
      return
    }
    setError('')
    try {
      await loadDocuments()
    } catch (error) {
      console.error('useDocuments', error)
      setError(error.message)
    }
    setLoading(false)
  }, [loadDocuments, loadingSession, setLoading, setError])

  const applyFilter = useCallback(async (newFilter) => {
    setFilterToApply(newFilter) // newFilter can also be a function to determine new filter from previous
  }, [setFilterToApply])
  const clearFilter = useCallback(async () => {
    setFilterToApply({})
  }, [setFilterToApply])

  // apply a new filter object, when it is different from currently set filter
  useEffect(() => {
    if (isEqual(filterToApply, filter)) {
      return
    }
    updateQueryParams(withoutDefaultParams(filterToApply, defaultFilterParams))// only set queryString to non default params
    setFilter(filterToApply)
  }, [filterToApply, filter, defaultFilterParams, updateQueryParams])

  // refresh when filter changes
  useEffect(() => {
    if (filter === undefined) {
      return
    }
    refresh()
  }, [refresh, filter])

  // apply filter when queryParams change
  useEffect(() => {
    // do not change, when route is not search
    if (!window.location.href.includes('search')) {
      return
    }
    if (!Object.keys(queryParams).length) {
      applyFilter({
        ...defaultFilterParams,
        language
      })
      return
    }
    applyFilter({
      ...defaultFilterParams,
      ...queryParams,
      language
    })
  }, [applyFilter, queryParams, language, defaultFilterParams])

  const getAvailableTags = useCallback(async () => {
    if (!session?.user) {
      return []
    }
    return Backend.getAvailableTags(session.user.id, language, session.token)
  }, [session, language])

  const trackDocumentView = useCallback(async (documentId) => {
    return Backend.trackDocumentView(documentId, session.token)
  }, [session])

  const value = {
    documents,
    _setDocuments: setDocuments,
    total,
    loading,
    setLoading,
    error,
    filter,
    defaultFilterParams,
    refresh,
    applyFilter,
    clearFilter,
    getAvailableTags,
    remainingTagIds,
    trackDocumentView,
    contentReady,
    setContentReady,
    hideContent
  }

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>
}

export default useDocuments
