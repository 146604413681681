import styled from 'styled-components'

import Typography from '@mui/material/Typography'

import theme from '../../../theme'

const ReadOnlyField = ({
  label = '',
  value = '',
  ...restProps
}) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `

  const Label = styled(Typography).attrs({
    variant: 'caption'
  })`
    color: ${theme.palette.neutral[600]};
  `

  const Value = styled(Typography).attrs({
    variant: 'p'
  })`
    color: ${theme.palette.neutral[600]};
  `

  return (
    <Container {...restProps}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  )
}

export default ReadOnlyField
