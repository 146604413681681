import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '@mui/material/Button'

import KeyIcon from '@mui/icons-material/Key'

import theme from '../../../theme'
import Divider from '../../common/Divider'
import Headline from '../../common/Headline'
import Subheadline from '../../common/Subheadline'
import Paragraph from '../../common/Paragraph'
import ReadOnlyField from './ReadOnlyField'
import ChangePasswordModal from './ChangePasswordModal'
import useAuth from '../../hooks/useAuth'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${theme.spacing * 5}px;
  padding-left: ${theme.spacing * 14}px;
  padding-right: ${theme.spacing * 14}px;
  gap: ${theme.spacing * 3}px;
  background-color: ${theme.palette.shade[0]};
`

const StyledDividerMorePadding = styled(Divider)`
  margin: ${theme.spacing * 2}px 0 ${theme.spacing * 2}px 0 !important;
`

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  row-gap: ${theme.spacing * 2}px;
`

const ReadOnlyFieldHalfWidth = styled(ReadOnlyField)`
  width: 50%;
`

const Profile = () => {
  const { t } = useTranslation()
  const { session, loadUserData } = useAuth()
  const [userData, setUserData] = useState()

  useEffect(() => {
    loadUserData()
      .then(userData => setUserData(userData))
      .catch(console.error)
  }, [session, loadUserData])

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <StyledContainer>
      <Headline>{t('profile.headline')}</Headline>
      <Paragraph>{t('profile.description')}</Paragraph>
      <Divider />
      <Subheadline>{t('profile.userData.headline')}</Subheadline>
      <SectionContainer>
        <ReadOnlyFieldHalfWidth value={`${userData?.firstname || ''} ${userData?.lastname || ''}`} label={t('profile.userData.name')} />
        <ReadOnlyFieldHalfWidth value={userData?.email} label={t('profile.userData.email')} />
        <ReadOnlyFieldHalfWidth value={userData?.company} label={t('profile.userData.company')} />
      </SectionContainer>
      <StyledDividerMorePadding />
      <Subheadline>{t('profile.password.headline')}</Subheadline>
      <SectionContainer>
        <ReadOnlyFieldHalfWidth value='******' label={t('profile.password.currentPassword')} />
        <Button
          variant='outlined'
          startIcon={<KeyIcon />}
          onClick={handleOpen}
        >
          {t('profile.password.changePasswordButton')}
        </Button>
      </SectionContainer>
      <StyledDividerMorePadding />
      <ChangePasswordModal open={open} onClose={handleClose} />
    </StyledContainer>
  )
}

export default Profile
