import styled from 'styled-components'
import { Container, Paper } from '@mui/material'

import Modal from '@mui/material/Modal'

import theme from '../../../../theme'
import ChangePassword from './ChangePassword'

const StyledModal = styled(Modal)`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled(Container)`
  min-width: ${theme.spacing * 52}px;
  max-width: ${theme.spacing * 52}px;
  padding-top: ${theme.spacing * 3}px;
  padding-bottom: ${theme.spacing * 5}px;
`

const StyledPaper = styled(Paper)`
  border-radius: ${theme.spacing * 2}px !important;
`

const ChangePasswordModal = ({ open, onClose: handleClose, ...restProps }) => {
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
    >
      <StyledPaper elevation={2}>
        <StyledContainer maxWidth={false}>
          <ChangePassword onClose={handleClose} />
        </StyledContainer>
      </StyledPaper>
    </StyledModal>
  )
}

export default ChangePasswordModal
