import styled from 'styled-components'
import { Link } from 'wouter'
import Typography from '@mui/material/Typography'
import theme from '../../theme'

const CenterTypography = styled(Typography)`
  text-align: center;
  height: auto;
`

const StyledLink = (Element) => Element`
text-align: center;
color: ${({ color }) => color};
&:hover {
  color: ${({ color }) => color};
}
&:visited {
  color: ${({ color }) => color};
}
&:link {
  color: ${({ color }) => color};
}
&:active {
  color: ${({ color }) => color};
}
`

const InfoLink = ({
  href,
  target,
  children,
  color = theme.palette.neutral.main,
  ...restProps
}) => {
  const LinkElement = StyledLink((href.includes('://') || href.includes('mailto')) ? styled.a : styled(Link))

  return (
    <CenterTypography variant='pSmall' {...restProps}>
      <LinkElement href={href} target={target} color={color}>
        {children}
      </LinkElement>
    </CenterTypography>
  )
}

export default InfoLink
