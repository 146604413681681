import styled from 'styled-components'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ButtonLink from './ButtonLink'
import InfoLink from './InfoLink'
import Header from './Header'

const UnderlinedTextButton = styled(Button)`
  text-decoration: underline !important;
`

const Result = ({
  title,
  description,
  buttonText,
  buttonLink,
  buttonAction,
  buttonSecondaryText,
  buttonSecondaryLink,
  buttonSecondaryAction,
  Icon,
  ...restProps
}) => {
  return (
    <Stack spacing={3}>
      <Header
        title={title}
        description={description}
        Icon={Icon}
      />
      {buttonLink && <ButtonLink href={buttonLink}>{buttonText}</ButtonLink>}
      {buttonAction && <Button type='submit' fullWidth variant='contained' onClick={buttonAction}>{buttonText}</Button>}
      {buttonSecondaryLink && <InfoLink href={buttonSecondaryLink}>{buttonSecondaryText}</InfoLink>}
      {buttonSecondaryAction && <UnderlinedTextButton onClick={buttonSecondaryAction}>{buttonSecondaryText}</UnderlinedTextButton>}
    </Stack>
  )
}

export default Result
