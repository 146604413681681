import { Worker } from '@react-pdf-viewer/core'

import AppContextProvider from './AppContextProvider'
import PageRouting from './Pages'
import '@fontsource/open-sans'

const workerLocal = `${process.env.PUBLIC_URL}/pdf.worker.min.js`

const App = () => {
  return (
    <Worker workerUrl={workerLocal}>
      <AppContextProvider>
        <PageRouting />
      </AppContextProvider>
    </Worker>
  )
}

export default App
