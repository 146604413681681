import styled from 'styled-components'

import Typography from '@mui/material/Typography'

import theme from '../../theme'

const Headline = styled(Typography).attrs({
  variant: 'h7'
})`
  color: ${theme.palette.primary.main};
  padding-bottom: ${theme.spacing * 1}px;
`

export default Headline
