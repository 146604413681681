import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import Result from '../../../common/Result'

import useAuth from '../../../hooks/useAuth'
import Validator from '../../../common/validator'

const Subheadline = styled(Typography).attrs({
  variant: 'pSemiBold',
  color: 'primary'
})`
  text-align: center;
  width: 100%;
`

const UnderlinedTextButton = styled(Button)`
  text-decoration: underline !important;
`

const ChangePassword = ({ onClose: handleClose, ...restProps }) => {
  const { t } = useTranslation()
  const { changePassword } = useAuth()

  const [currentPassword, setCurrentPassword] = useState('')
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [currentPasswordError, setCurrentPasswordError] = useState()

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState()

  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [passwordRepeatError, setPasswordRepeatError] = useState()

  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)

  const clearValidation = useCallback(() => {
    setCurrentPasswordError()
    setPasswordError()
    setPasswordRepeatError()
  }, [setPasswordError, setPasswordRepeatError])

  const validate = useCallback(() => {
    clearValidation()
    if (!currentPassword || !Validator.userPassword(currentPassword)) {
      setCurrentPasswordError(t('profile.changePassword.passwordInput.invalidLength'))
      return false
    }
    if (!password || !Validator.userPassword(password)) {
      setPasswordError(t('profile.changePassword.passwordInput.invalidLength'))
      return false
    }
    if (!passwordRepeat || (password !== passwordRepeat)) {
      setPasswordRepeatError(t('profile.changePassword.passwordRepeatInput.invalid'))
      return false
    }
    return true
  }, [t, clearValidation, currentPassword, setCurrentPasswordError, password, setPasswordError, passwordRepeat, setPasswordRepeatError])

  useEffect(() => {
    clearValidation()
  }, [clearValidation, currentPassword, password, passwordRepeat])

  const handleChangePassword = useCallback(async (event) => {
    event.preventDefault()

    if (!validate()) {
      return false
    }

    setLoading(true)
    try {
      await changePassword(currentPassword, password)
      setResult(true)
    } catch (error) {
      console.error(error)
      setResult(error.message)
    }
    setLoading(false)
  }, [validate, changePassword, currentPassword, password, setResult])

  // error result
  if (result && result !== true) {
    return (
      <Result
        title={t('profile.changePassword.title')}
        description={t('profile.changePassword.errorResult.description')}
        buttonText={t('profile.changePassword.confirmation.actionOk')}
        buttonAction={handleClose}
        buttonSecondaryText={t('profile.changePassword.errorResult.actionRetry')}
        buttonSecondaryAction={() => setResult(false)}
        Icon={ErrorOutlineIcon}
      />
    )
  }

  // success result
  if (result && result === true) {
    return (
      <Result
        title={t('profile.changePassword.confirmation.title')}
        description={t('profile.changePassword.confirmation.description')}
        buttonText={t('profile.changePassword.confirmation.actionOk')}
        buttonAction={() => {
          handleClose()
          setResult(false)
        }}
        Icon={CheckCircleOutlined}
      />
    )
  }

  return (
    <Stack component='form' onSubmit={handleChangePassword} spacing={3} {...restProps}>
      <Subheadline>{t('profile.changePassword.title')}</Subheadline>

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='current-password' error={!!currentPasswordError}>{t('profile.changePassword.currentPasswordInput.title')}</InputLabel>
        <OutlinedInput
          id='current-password'
          type={showCurrentPassword ? 'text' : 'password'}
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle current password visibility'
                onClick={() => setShowCurrentPassword(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('profile.changePassword.currentPasswordInput.title')}
          error={!!currentPasswordError}
        />
        {currentPasswordError && <FormHelperText error id='current-password-helper-text'>{currentPasswordError}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='password' error={!!passwordError}>{t('profile.changePassword.passwordInput.title')}</InputLabel>
        <OutlinedInput
          id='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('profile.changePassword.passwordInput.title')}
          error={!!passwordError}
        />
        {passwordError && <FormHelperText error id='password-helper-text'>{passwordError}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='password-repeat' error={!!passwordRepeatError}>{t('profile.changePassword.passwordRepeatInput.title')}</InputLabel>
        <OutlinedInput
          id='password-repeat'
          type={showPasswordRepeat ? 'text' : 'password'}
          value={passwordRepeat}
          onChange={e => setPasswordRepeat(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPasswordRepeat(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('profile.changePassword.passwordRepeatInput.title')}
          error={!!passwordRepeatError}
        />
        {passwordRepeatError && <FormHelperText error id='password-repeat-helper-text'>{passwordRepeatError}</FormHelperText>}
      </FormControl>

      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        loading={loading}
      >
        {t('profile.changePassword.setPasswordButton')}
      </LoadingButton>

      <UnderlinedTextButton onClick={handleClose}>{t('profile.changePassword.cancelButton')}</UnderlinedTextButton>
    </Stack>
  )
}

export default ChangePassword
