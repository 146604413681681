import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Chip from '@mui/material/Chip'
import CancelIcon from '@mui/icons-material/Cancel'

import theme from '../../../../theme'
import useDocuments from '../../../hooks/useDocuments'
import TypeEntry from './TypeEntry'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: ${theme.spacing * 6}px;
  row-gap: ${theme.spacing * 2}px;
  flex-wrap: wrap
`

const SelectedContainer = styled.div`
  margin-top: ${theme.spacing * 3}px;
  display: flex;
  gap: ${theme.spacing * 3}px;
  flex-wrap: wrap;
`

const SelectedItem = styled(Chip)`
  height: ${theme.spacing * 5}px !important;
  border-radius: ${theme.spacing * 3}px !important;
  font-size: 12px !important;
  color: ${theme.palette.neutral.main} !important;
  background-color: white !important;
  box-shadow: ${theme.shadow.chip} !important;
  padding-left: ${theme.spacing * 1}px !important;
  padding-right: ${theme.spacing * 1}px !important;
  &:hover {
    color: ${theme.palette.primary[900]} !important;
    box-shadow: ${theme.shadow.chipHover} !important;
  }
`

const StyledCancelIcon = styled(CancelIcon)`
  font-size: 19px !important;
  color: ${theme.palette.primary.main} !important;
  &:hover {
    color: ${theme.palette.primary[900]} !important;
  }
`

const byLocalizedTitleAscending = (language) => (a, b) => {
  try {
    if (a[`title_${language}`].toLowerCase() < b[`title_${language}`].toLowerCase()) { return -1 }
    if (a[`title_${language}`].toLowerCase() > b[`title_${language}`].toLowerCase()) { return 1 }
  } catch (error) {
    console.log(error)
  }
  return 0
}

const filterMenuOrder = [
  'products',
  'industries',
  'materials',
  'applications',
  'docarts',
  'themes',
  'brands'
]

const TagFilter = ({ onChange: handleChange, ...restProps }) => {
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage === 'de' ? 'de' : 'en'

  const { getAvailableTags, filter, remainingTagIds } = useDocuments()
  const [availableTags, setAvailableTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [tagLookup, setTagLookup] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      return
    }
    if (!filter?.tags?.length) {
      setSelectedTags([])
      return
    }
    setSelectedTags(filter.tags)
  }, [setSelectedTags, filter, loading])

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      const tags = (await getAvailableTags()).filter(t => t.type !== 'meta')

      setTagLookup(tags.reduce((acc, tag) => {
        acc[tag.id] = tag
        return acc
      }, {}))

      const selectableTags = tags
        .map(tag => ({
          ...tag,
          isSelectable: remainingTagIds.includes(tag.id)
        }))

      const tagsByType = selectableTags.reduce((acc, tag) => {
        if (!acc[tag.type]) {
          acc[tag.type] = []
        }
        acc[tag.type].push(tag)
        return acc
      }, [])

      const fetchedAvailableTags = Object.entries(tagsByType).reduce((acc, [key, entries]) => {
        acc.push({
          key,
          title: t(`search.tagTypes.${key}`),
          entries
        })
        return acc
      }, [])
        .sort((a, b) => {
          try {
            const orderNumberA = filterMenuOrder.indexOf(a.key)
            const orderNumberB = filterMenuOrder.indexOf(b.key)

            if (orderNumberA < orderNumberB) {
              return -1
            }
            if (orderNumberA > orderNumberB) {
              return 1
            }
          } catch (error) {
            console.error(error)
          }
          return 0
        })

      const tagsWithSortedEntries = fetchedAvailableTags.map(tag => {
        tag.entries.sort(byLocalizedTitleAscending(language))
        return tag
      })

      setAvailableTags(tagsWithSortedEntries)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }, [t, getAvailableTags, setAvailableTags, language, remainingTagIds])

  useEffect(() => {
    refresh()
  }, [refresh])

  const handleRemove = useCallback(tagId => event => {
    event.stopPropagation()
    setSelectedTags(prev => {
      const newSelectedTags = prev.filter(id => id !== tagId)
      handleChange(newSelectedTags)
      return newSelectedTags
    })
  }, [setSelectedTags, handleChange])

  const handleSelect = useCallback(newSelectedTagsFn => {
    setSelectedTags(prev => {
      const newSelectedTags = newSelectedTagsFn(prev)
      handleChange(newSelectedTags)
      return newSelectedTags
    })
  }, [setSelectedTags, handleChange])

  return (
    <Container {...restProps}>
      <SelectionContainer>
        {availableTags.map(({ key, title, entries }) => {
          return (
            <TypeEntry
              language={language}
              key={key}
              id={key}
              title={title.toUpperCase()}
              entries={entries}
              selectedEntries={selectedTags}
              onSelect={handleSelect}
            />
          )
        })}
      </SelectionContainer>
      <SelectedContainer>
        {selectedTags.map((tagId) => {
          const tagInfo = tagLookup[tagId]
          return (
            <SelectedItem
              key={tagInfo.id}
              label={tagInfo[`title_${language}`]}
              onDelete={handleRemove(tagId)}
              onClick={handleRemove(tagId)}
              deleteIcon={<StyledCancelIcon />}
            />
          )
        })}
      </SelectedContainer>
    </Container>
  )
}

export default TagFilter
