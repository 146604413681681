import styled from 'styled-components'

import Typography from '@mui/material/Typography'

import theme from '../../theme'

const Paragraph = styled(Typography).attrs({
  variant: 'p'
})`
  color: ${theme.palette.neutral[600]};
  padding-bottom: ${theme.spacing * 1}px;
  white-space: pre-line;
`

export default Paragraph
