import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BackgroundImage from './assets/Background.png'
import ProductlineItem from './ProductlineItem.js'
import Grid from '@mui/material/Grid'
import theme from '../../../../theme'

import JetLogo from './assets/JetLogo.svg'
import LabelLogo from './assets/LabelLogo.svg'
import SystemsLogo from './assets/SystemsLogo.svg'
import VerifierLogo from './assets/VerifierLogo.svg'

import useLanguage from '../../../hooks/useLanguage'
import useDocuments from '../../../hooks/useDocuments'

const ProductlineBackground = styled.div`
  height: ${theme.spacing * 45}px;
  background-image: url(${BackgroundImage});
  background-position: center center;
  background-size: cover;
  width: ${navigator.userAgent.includes('Windows') ? `calc(100vw - ${theme.spacing * 2 + 1}px)` : '100vw'};
  display: flex;
  flex-direction: column;
  align-items:center;
  padding: 0 ${theme.spacing * 9}px;
`

const ContentGrid = styled(Grid)`
  max-width: ${theme.maxContentWidth};
`

const productlineDetails = {
  JET: {
    logo: JetLogo,
    description: 'dashboard.productline.description.jet'
  },
  LABEL: {
    logo: LabelLogo,
    description: 'dashboard.productline.description.label'
  },
  SYSTEMS: {
    logo: SystemsLogo,
    description: 'dashboard.productline.description.systems'
  },
  VERIFIER: {
    logo: VerifierLogo,
    description: 'dashboard.productline.description.verifier'
  }
}

const ProductlineList = ({
  productlines,
  ...restProps
}) => {
  const { t } = useTranslation()
  const { linkWithLangParam } = useLanguage()
  const { setContentReady } = useDocuments()

  const productlineLink = id => linkWithLangParam(`/search?tags=${id}%2C`)

  const preventPrematureContentDisplay = () => setContentReady(false)

  return (
    <ProductlineBackground>
      <ContentGrid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >

        {productlines.map(productline => {
          const details = productlineDetails[productline.title_de]
          if (!details) return <></>
          return (
            <ProductlineItem
              onClick={preventPrematureContentDisplay}
              key={productline.id}
              id={productline.id}
              imageSrc={details.logo}
              link={productlineLink(productline.id)}
              count={productline.count}
            >
              {t(details.description)}
            </ProductlineItem>
          )
        })}
      </ContentGrid>
    </ProductlineBackground>
  )
}

export default ProductlineList
