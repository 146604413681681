import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ProfileIcon from '@mui/icons-material/PermIdentity'

import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import { Link, useLocation } from 'wouter'

import theme from '../../../theme'
import LogoImageDe from '../../common/Logo/logo_de.svg'
import LogoImageEn from '../../common/Logo/logo_en.svg'
import MenuLink from '../../common/MenuLink'
import LanguageSelect from '../../common/LanguageSelect'
import useAuth from '../../hooks/useAuth'
import useLanguage from '../../hooks/useLanguage'
import useDocuments from '../../hooks/useDocuments'

const FixedContainer = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background-color: ${theme.palette.shade[0]};
  box-shadow: ${theme.shadow.main};
  z-index: 1;
  width: 100vw !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledHeader = styled.div`
  flex: 1;
  max-width:${theme.maxContentWidth};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${theme.spacing * 10}px;
  margin-left: ${theme.spacing * 9}px;
  margin-right: ${theme.spacing * 9}px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.spacing * 3}px 0px;
`

const LogoImage = styled.img`
  cursor: pointer;
  width: 330px;
  line-height: 1rem;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${theme.spacing * 3}px;
`

const StyledMenuItem = styled(MenuItem)`
  height: ${theme.spacing * 5}px;
  padding-top: ${theme.spacing}px !important;
  padding-bottom: ${theme.spacing}px !important;
  &:hover {
    background-color: ${theme.palette.primary['50Transparent']} !important;
  }
`

const ProfileButton = styled(Button)`
  width: 100%;
  height: ${theme.button.navbar.height}px;
`

const StyledMenuText = styled(Typography)`
  color: ${({ active }) => active ? theme.palette.primary.main : theme.palette.neutral[500]};
`

const Header = (props) => {
  const { t } = useTranslation()
  const { linkWithLangParam, language } = useLanguage()
  const [anchorEl, setAnchorEl] = useState(null)
  const [, setLocation] = useLocation()
  const { logout } = useAuth()
  const { hideContent } = useDocuments()

  const logoSource = (language === 'de') ? LogoImageDe : LogoImageEn

  const options = [
    { name: t('header.navbarItems.documents'), link: linkWithLangParam('/search'), notActiveWithSearchParams: ['new', 'favorites', 'lastViewed'] },
    { name: t('header.navbarItems.newDocuments'), link: linkWithLangParam('/search?new=true') },
    { name: t('header.navbarItems.favorites'), link: linkWithLangParam('/search?favorites=true') },
    { name: t('header.navbarItems.history'), link: linkWithLangParam('/search?lastViewed=true') },
    { name: t('header.navbarItems.help'), link: '/help' }
  ]

  const open = Boolean(anchorEl)
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [setAnchorEl])
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])
  const handleLinkClick = useCallback((link) => () => {
    setLocation(link)
    handleMenuClose()
  }, [setLocation, handleMenuClose])

  const profileOptions = {
    name: t('header.navbarItems.profile'),
    children: [
      {
        name: t('header.navbarItems.ownProfile'),
        link: '/profile'
      },
      {
        name: t('header.navbarItems.logout'),
        handleClick: () => {
          logout()
          setLocation('/login')
        }
      }
    ]
  }

  const preventPrematureContentDisplay = () => {
    hideContent()
  }

  return (
    <FixedContainer {...props}>
      <StyledHeader>
        <LogoContainer>
          <Link href={linkWithLangParam('/')}>
            <LogoImage src={logoSource} alt='Logo' />
          </Link>
        </LogoContainer>
        <LinkContainer>
          {options.map((option) => {
            if (option.newTab) {
              return (
                <MenuLink key={option.link} href={option.link} target='_blank'>
                  {option.name}
                </MenuLink>
              )
            }
            let active = window.location.href.includes(option.link)
            if (active && option.notActiveWithSearchParams) {
              active = option.notActiveWithSearchParams.every(param => !window.location.search.includes(param))
            }
            return (
              <MenuLink onClick={active ? () => {} : preventPrematureContentDisplay} key={option.link} href={option.link} target={option.newTab ? '_blank' : '_self'} active={active ? 'active' : ''}>
                {option.name}
              </MenuLink>
            )
          })}
        </LinkContainer>
        <LinkContainer>
          <LanguageSelect />
          <ProfileButton variant='outlined' startIcon={<ProfileIcon />} onClick={handleMenuOpen}>
            {profileOptions.name}
          </ProfileButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            anchorOrigin={{
              vertical: theme.spacing * 6,
              horizontal: 'left'
            }}
          >
            {profileOptions.children.map((child, childIndex) => {
              const active = window.location.href.includes(child.link) ? 'active' : ''
              return (
                <StyledMenuItem
                  key={childIndex}
                  onClick={child.link ? handleLinkClick(child.link) : child.handleClick}
                >
                  <StyledMenuText variant='p' active={active}>
                    {child.name}
                  </StyledMenuText>
                </StyledMenuItem>
              )
            })}
          </Menu>
        </LinkContainer>
      </StyledHeader>
    </FixedContainer>
  )
}

export default Header
