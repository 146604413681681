import { useState, useCallback } from 'react'

import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import theme from '../../../../theme'
import useDocuments from '../../../hooks/useDocuments'

const TypeEntryContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${theme.palette.neutral[600]};
  &:hover {
    color: ${theme.palette.primary[500]};
  }
`

const TypeEntryButton = styled.div`
  display: flex;
  align-items: center;
  color: ${({ hasSelectableEntries }) => !hasSelectableEntries ? theme.palette.neutral[200] : ''};
`

const StyledMenu = styled(Menu)`
  pointer-events: ${({ $loading }) => $loading ? 'none' : 'all'};
  filter: ${({ $loading }) => $loading ? 'blur(1px)' : ''};
`

const ITEM_HEIGHT = theme.spacing * 5

const StyledMenuItem = styled(MenuItem)`
  height: ${ITEM_HEIGHT}px;
  padding-top: ${theme.spacing}px !important;
  padding-bottom: ${theme.spacing}px !important;
  color: ${({ $active = false, $selectable = true }) => {
    if (!$selectable) {
      return theme.palette.neutral[200]
    }
    return $active ? theme.palette.primary.main : theme.palette.neutral[500]
  }} !important;
  pointer-events: ${({ $selectable }) => $selectable ? '' : 'none'};
  &:hover {
    background-color: ${theme.palette.primary['50Transparent']} !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  color: ${({ disabled }) => disabled ? theme.palette.neutral[200] : ''} !important;
`

const StyledDot = styled.div`
  height: 10px;
  width: 10px;
  margin-left: ${theme.spacing}px;
  margin-bottom: 2px;
  border-radius: 5px;
  background-color: ${theme.palette.primary.main};
  visibility: ${props => props.hasSelectedEntries ? 'visible' : 'collapse'};
`

const CenteredIconContainer = styled.div`
  margin-top: 4px;
`

const OpenStateIcon = ({ isOpen }) => {
  return (
    <CenteredIconContainer>
      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </CenteredIconContainer>
  )
}

const TypeEntry = ({ id, title, entries, onSelect: handleSelect, selectedEntries, onClose, language, ...restProps }) => {
  const { loading } = useDocuments()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [setAnchorEl])
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
    if (onClose) {
      onClose()
    }
  }, [onClose, setAnchorEl])

  const handleEntryClick = useCallback((entry) => () => {
    handleSelect(prev => {
      // remove
      if (prev.includes(entry.id)) {
        return prev.filter(e => e !== entry.id)
      }
      // add
      return prev.concat(entry.id)
    })
  }, [handleSelect])

  const hasSelectedEntries = entries.some(entry => selectedEntries.includes(entry.id))
  const hasSelectableEntries = entries.some(entry => entry.isSelectable)

  return (
    <TypeEntryContainer>
      <TypeEntryButton onClick={handleMenuOpen} hasSelectableEntries={hasSelectableEntries}>
        <Typography
          variant='pSmallSemiBold'
          key={id}
        >
          {title}
        </Typography>
        <StyledDot hasSelectedEntries={hasSelectedEntries} />
        <OpenStateIcon isOpen={open} />
      </TypeEntryButton>
      <StyledMenu
        id={`filter-type-select-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: theme.spacing * 4, horizontal: 'left' }}
        PaperProps={{
          style: { maxHeight: ITEM_HEIGHT * 12.8 } // use .8 to show part of the last displayed element, so user knows there's more items
        }}
        $loading={loading}
      >
        {entries.map(entry => {
          const active = selectedEntries?.includes(entry.id)
          return (
            <StyledMenuItem
              key={entry.id}
              value={entry.id}
              onClick={handleEntryClick(entry)}
              $active={active}
              $selectable={active || entry.isSelectable}
            >
              <StyledCheckbox checked={active} disabled={!entry.isSelectable} />
              <Typography>{entry[`title_${language}`]}</Typography>
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </TypeEntryContainer>
  )
}

export default TypeEntry
