import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import styled from 'styled-components'

import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import Star from '@mui/icons-material/Star'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import theme from '../../../../theme'
import CoverPlay from './CoverPlay.svg'
import IconButtonContained from './IconButtonContained'
import DocumentDetailModal from '../DocumentDetailModal'

import { getThumbnail, getDownloadLink } from '../../../common/Backend'
import useFavorites from '../../../hooks/useFavorites'
import useDocuments from '../../../hooks/useDocuments'
import { buildDownloadFilename, buildCategoryString } from '../../../common/format'

const TypeChip = styled(Chip)`
  position: absolute;
  top: ${theme.spacing * 1}px;
  left: ${theme.spacing * 1}px;
  max-height: ${theme.spacing * 2}px;
  background-color: ${theme.palette.chip.main} !important;
  color: ${theme.palette.shade[0]} !important;
  padding-bottom: 2px !important;
`
const RelativeContainer = styled.div`
  position: relative;
`

const LabelContainer = styled(Stack)`
  padding-bottom: ${theme.spacing * 2}px;
  color: ${theme.palette.neutral.main};
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`

const NewDot = styled(Box)`
  background-color: ${theme.palette.accent.main};
  min-height: ${theme.spacing + 2}px;
  min-width: ${theme.spacing + 2}px;
  border-radius: ${theme.spacing + 2}px;
`

const CoverContainer = styled.div`
  width: 100%;
  background-color: ${theme.palette.shade['100Transparent']};
`

const Cover = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: ${({ $shouldCover }) => $shouldCover ? 'cover' : 'contain'};
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`

const PlayVideoImage = styled.img.attrs({
  src: CoverPlay
})`
  height: 60px;
  width: 60px;
`

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1) 3%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${theme.spacing * 2}px;
`

const getTypeString = (mimetype, t) => {
  if (!mimetype) {
    return undefined
  }
  if (mimetype === 'application/pdf') {
    return t('documentList.types.pdf')
  }
  if (mimetype.startsWith('image/')) {
    return t('documentList.types.image')
  }
  if (mimetype.startsWith('video/')) {
    return t('documentList.types.video')
  }
  if (mimetype === 'application/zip') {
    return t('documentList.types.zip')
  }
  if (['erpt', 'easm'].includes(mimetype.toLowerCase())) {
    return t('documentList.types.3d')
  }
  return undefined
}

const DocumentItem = ({
  doc,
  updateDocumentList,
  ...restProps
}) => {
  const { session } = useAuth()
  const { t, i18n } = useTranslation()
  const { favorites, add: addFavorite, remove: removeFavorite } = useFavorites()
  const { trackDocumentView, _setDocuments, filter } = useDocuments()
  const language = i18n.resolvedLanguage === 'de' ? 'de' : 'en'
  const fallbackLanguage = language === 'de' ? 'en' : 'de'

  const [isOpen, setIsOpen] = useState(false)
  const [coverUrl, setCoverUrl] = useState()
  const [hovering, setHovering] = useState(false)
  const [isFavorited, setIsFavorited] = useState(false)
  const [opendNewDocument, setOpendNewDocument] = useState(false)

  useEffect(() => {
    getThumbnail(doc.id).then(coverUri => {
      setCoverUrl(coverUri)
    })
  }, [doc?.id])

  useEffect(() => {
    setIsFavorited(favorites.find(favorite => favorite.documentId === doc.id))
  }, [favorites, doc?.id])

  const isNew = doc.isNew
  const category = useMemo(() => buildCategoryString(doc.tags, language), [doc.tags, language])
  const typeString = getTypeString(doc.mimetype, t)
  const downloadFilename = useMemo(() => buildDownloadFilename(doc, language), [doc, language])

  const stopEventPropagation = useCallback(event => event.stopPropagation(), [])

  const handleToggleFavorite = useCallback(async event => {
    stopEventPropagation(event)
    if (isFavorited) {
      // directly remove document from favorite list on favorite removal
      if (filter?.favorites === 'true') {
        _setDocuments((prevDocs) => prevDocs.filter(d => d.id !== doc.id))
      }
      try {
        return removeFavorite(doc.id)
      } catch (error) {
        console.error(error)
      }
    }
    return addFavorite(doc.id)
  }, [doc, isFavorited, removeFavorite, addFavorite, stopEventPropagation, filter, _setDocuments])

  const handleOpen = useCallback(async event => {
    stopEventPropagation(event)
    setHovering(false)
    setIsOpen(true)
    // directly remove document new status from local list on document view
    if (isNew) { setOpendNewDocument(true) }
    _setDocuments(prevDocs => prevDocs.map(d => ({
      ...d,
      isNew: (d.id === doc.id) ? false : d.isNew
    })))
    try {
      return trackDocumentView(doc.id)
    } catch (error) {
      console.error(error)
    }
  }, [doc, setIsOpen, trackDocumentView, stopEventPropagation, _setDocuments, isNew])

  const handleClose = useCallback(event => {
    stopEventPropagation(event)
    setIsOpen(false)
    if (opendNewDocument || isNew) {
      updateDocumentList()
    }
  }, [setIsOpen, stopEventPropagation, isNew, opendNewDocument, updateDocumentList])

  return (
    <Grid
      item
      xs={2}
      onClick={handleOpen}
      onMouseEnter={e => setHovering(true)}
      onMouseLeave={e => setHovering(false)}
    >
      <Stack gap={1} {...restProps}>
        <RelativeContainer>
          <CoverContainer>
            <Cover src={coverUrl} $shouldCover={doc.mimetype !== 'application/pdf'}>
              {doc.mimetype.startsWith('video/') && <PlayVideoImage />}
            </Cover>
          </CoverContainer>
          {typeString && <TypeChip size='small' label={<Typography variant='footer'>{typeString}</Typography>} />}
          {hovering &&
            <GradientOverlay>
              <IconButtonContained
                onClick={handleToggleFavorite}
                icon={isFavorited ? Star : StarOutlineOutlinedIcon}
              />
              <IconButtonContained
                href={getDownloadLink(doc.id, { token: session.token, downloadFilename })}
                onClick={stopEventPropagation}
                icon={FileDownloadOutlinedIcon}
              />
            </GradientOverlay>}
        </RelativeContainer>
        <BottomContainer>
          {isNew && <NewDot />}
          <LabelContainer gap={1}>
            <Typography color='neutral' variant='pSmallSemiBold'>{category}</Typography>
            <Typography color='neutral' variant='pSmall'>{doc[`title_${language}`] || doc[`title_${fallbackLanguage}`]}</Typography>
          </LabelContainer>
        </BottomContainer>
      </Stack>
      <DocumentDetailModal
        open={isOpen}
        doc={doc}
        onClose={handleClose}
      />
    </Grid>
  )
}

export default DocumentItem
