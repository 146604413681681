import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import theme from '../../theme'

const ProgressCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing * 1}px;
  height: 100vh;
`

const Loading = () => {
  const { t } = useTranslation()
  return (
    <ProgressCenteredContainer>
      <CircularProgress />
      <Typography variant='p' color='secondary'>{t('loading')}</Typography>
    </ProgressCenteredContainer>
  )
}

export default Loading
