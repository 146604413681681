import { useState, useContext, createContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useQueryParams from './useQueryParams'

const LanguageContext = createContext()

export const useLanguage = () => {
  const context = useContext(LanguageContext)

  if (context === undefined) {
    throw new Error('useLanguage must be used within an LanguageProvider')
  }

  return context
}

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const resolvedLanguage = i18n.resolvedLanguage === 'de' ? 'de' : 'en'
  const [queryParams] = useQueryParams()
  const [language, setLanguage] = useState(queryParams.language || resolvedLanguage)

  const changeLanguage = useCallback(lng => {
    i18n.changeLanguage(lng)
    setLanguage(lng)
  }, [i18n])

  const linkWithLangParam = useCallback(baseLink => {
    if (language !== resolvedLanguage) {
      if (baseLink.includes('?')) {
        const [baseLinkHead, ...baseLinkTail] = baseLink.split('?')
        return `${baseLinkHead}?language=${language}&${baseLinkTail.join('?')}`
      }
      return `${baseLink}?language=${language}`
    }
    return baseLink
  }, [language, resolvedLanguage])

  const value = {
    language,
    setLanguage,
    resolvedLanguage,
    linkWithLangParam,
    changeLanguage
  }

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

export default useLanguage
