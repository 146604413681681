import styled from 'styled-components'

import Header from './Header'
import Footer from './Footer'
import Version from '../../common/VersionText'

import theme from '../../../theme'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const ContentBackground = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.palette.background.light};
  padding-left: ${theme.spacing * 9}px;
  padding-right: ${theme.spacing * 9}px;
`
const StyledContent = styled.div`
  min-height: 85vh;
  max-width: ${theme.maxContentWidth};
  width: 100%;
  margin-top: ${theme.spacing * 10}px;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.spacing * 1}px;
  padding-bottom: ${theme.spacing * 2}px;
  width: 100%;
  background-color: ${theme.palette.background.light};
`

const FixedVersion = styled(Version)`
  color: ${theme.palette.neutral[500]} !important;
  position: absolute;
  bottom: ${theme.spacing * 1}px;
  right: ${theme.spacing * 1}px;
`

const Page = ({ children, ...restProps }) => {
  return (
    <MainContainer>
      <Header />
      <ContentBackground>
        <StyledContent>
          {children}
        </StyledContent>
      </ContentBackground>
      <FooterContainer>
        <Footer color={theme.palette.shade[100]} />
      </FooterContainer>
      <FixedVersion />
    </MainContainer>
  )
}

export default Page
