import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import theme from '../../../theme'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing * 3}px;
  padding: 20px;
  border-radius: ${theme.spacing * 2}px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  background-color: ${theme.palette.shade[0]};
`

const SearchIcon = styled(SearchOutlinedIcon)`
  display: flex;
  font-size: 20px !important;
  color: ${theme.palette.neutral[400]};
`

const StyledInput = styled.input`
  display: flex;
  flex-grow: 1;
  border: 0;
  line-height: 1.5rem;
  background-color: transparent;
  font-size: ${theme.typography.p.fontSize};
  color: ${theme.palette.neutral[600]};
  &:focus-visible {
    outline: 0;
  }
  &::placeholder {
    color: ${theme.palette.neutral[400]};
  }
`

const ClearIcon = styled(CloseOutlinedIcon)`
  font-size: 16px !important;
  color: ${theme.palette.shade[0]};
`

const ClearButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px !important;
  height: 20px !important;
  border-radius: ${theme.spacing * 2}px;
  background-color: ${theme.palette.neutral[400]};
  cursor: pointer;
`

const SearchInput = ({
  initValue = '',
  placeholderText,
  onChange: handleChange,
  ...restProps
}) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(initValue)
  }, [setValue, initValue])

  const handleInputChange = useCallback(e => {
    const newValue = e.target.value || ''
    setValue(newValue)
    handleChange && handleChange(newValue, 'input')
  }, [setValue, handleChange])

  const handleClearButtonClick = useCallback(() => {
    setValue('')
    handleChange && handleChange('', 'clear')
  }, [setValue, handleChange])

  const handleReturnKeyPress = useCallback(event => {
    if (['Enter', 'NumpadEnter'].includes(event.code)) {
      handleChange && handleChange(value, 'enter')
    }
  }, [handleChange, value])

  return (
    <StyledContainer {...restProps}>
      <SearchIcon />
      <StyledInput
        value={value}
        placeholder={placeholderText}
        onChange={handleInputChange}
        onKeyDown={handleReturnKeyPress}
      />
      {value &&
        <ClearButton onClick={handleClearButtonClick}>
          <ClearIcon />
        </ClearButton>}
    </StyledContainer>
  )
}

export default SearchInput
