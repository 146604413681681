import styled from 'styled-components'

import Typography from '@mui/material/Typography'

import theme from '../../theme'

const Subheadline = styled(Typography).attrs({
  variant: 'pSemiBold'
})`
  color: ${theme.palette.neutral[600]};
`

export default Subheadline
