import { saveSession } from './storage'

const backendEndpoint = process.env.REACT_APP_BACKEND || 'https://salesapp-backend-preview.rea.hpm.agency'

const ErrorsCausingLogout = ['jwt expired', 'invalid signature', 'invalid token']

export const fetchJson = async ({ method = 'GET', path = '', params = {}, headers = {}, token }) => {
  const headersEnhanced = {
    'Content-Type': 'application/json',
    ...headers
  }
  if (token) {
    headersEnhanced.Authorization = `Token ${token}`
  }

  let url = `${backendEndpoint}/${path}`

  const req = {
    method,
    headers: headersEnhanced
  }

  if (['POST', 'PUT'].includes(method)) {
    req.body = JSON.stringify(params)
  } else {
    const search = Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    if (search) {
      url = `${url}?${search}`
    }
  }

  const res = await fetch(url, req)
  const parsedRes = await res.json()

  if (res.status === 401 || ErrorsCausingLogout.includes(parsedRes.error)) {
    saveSession()
    window.location.reload()
  }

  if (parsedRes.error) {
    throw new Error(parsedRes.error)
  }

  return parsedRes
}

export const authorize = async (email, password) => {
  const res = await fetchJson({
    method: 'POST',
    path: 'users/authorize',
    params: {
      email,
      password
    }
  })

  return {
    token: res.token,
    user: {
      id: res.user.id,
      salutation: res.user.salutation,
      firstname: res.user.firstname,
      lastname: res.user.lastname,
      email: res.user.email,
      company: res.user.company,
      language: res.user.language
    }
  }
}

export const getUserData = async (userid, token) => {
  return fetchJson({
    method: 'GET',
    path: `users/${userid}`,
    token
  })
}

export const setUserPassword = async (password, token) => {
  return fetchJson({
    method: 'PUT',
    path: 'users/setpassword',
    params: {
      password
    },
    token
  })
}

export const changeUserPassword = async (password, newPassword, token) => {
  return fetchJson({
    method: 'PUT',
    path: 'users/setpassword',
    params: {
      oldPassword: password,
      password: newPassword
    },
    token
  })
}

export const requestUserPasswordReset = async (email) => {
  return fetchJson({
    method: 'POST',
    path: 'users/resetpassword',
    params: {
      email
    }
  })
}

export const getDashboardData = async ({ user, ...filter }, token) => {
  return fetchJson({
    method: 'GET',
    path: `users/${user.id}/dashboard`,
    params: filter,
    token
  })
}

export const getDocuments = async (userid, token, filter) => {
  return fetchJson({
    method: 'GET',
    path: `users/${userid}/documents`,
    token,
    params: filter
  })
}

export const getThumbnail = async (id) => {
  const { targetUrl } = await fetchJson({
    method: 'GET',
    path: `documents/${id}/download/true`,
    params: {
      returnUrl: true
    }
  })
  return targetUrl
}

export const getDownloadLink = (id, options) => {
  // filename (without extensions) for download file can be specified with options.downloadFilename
  let url = `${backendEndpoint}/documents/${id}/download`
  if (typeof options === 'object' && Object.keys(options).length) {
    url += `?${new URLSearchParams(options).toString()}`
  }
  return url
}

export const getThumbnailLink = (id) => `${backendEndpoint}/documents/${id}/download/true`

export const getFavorites = async (userId, token) => {
  const { favorites } = await fetchJson({
    method: 'GET',
    path: `users/${userId}/favorites`,
    token
  })
  return favorites
}

export const addFavorite = async (userId, documentId, token) => {
  return fetchJson({
    method: 'POST',
    path: `users/${userId}/favorites`,
    params: {
      documentid: documentId
    },
    token
  })
}

export const removeFavorite = async (userId, favoriteId, token) => {
  return fetchJson({
    method: 'DELETE',
    path: `users/${userId}/favorites/${favoriteId}`,
    token
  })
}

export const getAvailableTags = async (userId, language, token) => {
  const { tags } = await fetchJson({
    method: 'GET',
    path: `users/${userId}/tags`,
    params: {
      language
    },
    token
  })
  return tags
}

export const trackDocumentView = async (documentId, token) => {
  return fetchJson({
    method: 'POST',
    path: `documents/${documentId}/views`,
    token
  })
}

const Backend = {
  fetchJson,
  authorize,
  getUserData,
  setUserPassword,
  changeUserPassword,
  requestUserPasswordReset,
  getDashboardData,
  getDocuments,
  getThumbnail,
  getDownloadLink,
  getFavorites,
  addFavorite,
  removeFavorite,
  getAvailableTags,
  trackDocumentView,
  getThumbnailLink
}

export default Backend
