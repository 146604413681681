import styled from 'styled-components'

import { useTheme } from '@mui/material/styles'

import Typography from '@mui/material/Typography'

import Logo from './Logo'

const CenterTypography = styled(Typography)`
  text-align: center;
`
const CenterLogo = styled(CenterTypography)`
  line-height: 1rem;
`

const FixedSizeLogo = styled(Logo)`
  width: 95%;
`

const Header = ({ title, description, Icon, ...restProps }) => {
  const theme = useTheme()
  return (
    <>
      <CenterTypography color={theme.palette.primary.main} variant='pSemiBold'>{title}</CenterTypography>
      <CenterTypography><FixedSizeLogo /></CenterTypography>
      {Icon && <CenterLogo variant='iconLarge' color={theme.palette.neutral[400]}><Icon fontSize='inherit' /></CenterLogo>}
      <CenterTypography color={theme.palette.neutral[600]} variant='p'>{description}</CenterTypography>
    </>
  )
}

export default Header
