const sessionStorageKey = 'session'

export const loadSession = () => {
  try {
    return JSON.parse(window.localStorage.getItem(sessionStorageKey))
  } catch (error) {}
}

export const saveSession = (session) => {
  if (!session) {
    window.localStorage.removeItem(sessionStorageKey)
    return true
  }
  window.localStorage.setItem(sessionStorageKey, JSON.stringify(session))
  return true
}
