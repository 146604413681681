import styled from 'styled-components'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'

import theme from '../../theme'
import useLanguage from '../hooks/useLanguage'

const StyledToggleButton = styled(ToggleButton)`
  width: 100%;
  height: ${theme.button.navbar.height}px;
  border: 1px solid ${({ selected }) => selected ? theme.palette.primary[500] : theme.palette.neutral[400]} !important;
  color: ${({ selected }) => selected ? theme.palette.primary[500] : theme.palette.neutral[400]} !important;
  background-color: transparent !important;
  &:hover {
    color: ${({ selected }) => selected ? '' : theme.palette.primary[700]} !important;
  }
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const LanguageSelect = ({ ...restProps }) => {
  const { language, changeLanguage } = useLanguage()

  const handleChange = (event, value) => {
    // do nothing if button for currently selected language is clicked
    if (!value) {
      return
    }
    changeLanguage(value)
  }

  return (
    <StyledContainer {...restProps}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleChange}
        aria-label='language filter'
      >
        <StyledToggleButton value='en'>
          <Typography variant='pSmallSemiBold'>EN</Typography>
        </StyledToggleButton>
        <StyledToggleButton value='de'>
          <Typography variant='pSmallSemiBold'>DE</Typography>
        </StyledToggleButton>
      </ToggleButtonGroup>
    </StyledContainer>
  )
}

export default LanguageSelect
