import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import { isExpired } from 'react-jwt'
import Backend from '../common/Backend'
import { loadSession, saveSession } from '../common/storage'

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const refresh = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      let loadedSession = await loadSession()
      // clear expired token
      if (loadedSession?.token && isExpired(loadedSession.token)) {
        loadedSession = undefined
      }
      saveSession(loadedSession)
      setSession(loadedSession)
    } catch (error) {
      console.error('useAuth.refresh', error)
      setError(error.message)
    }
    setLoading(false)
  }, [setLoading, setError, setSession])

  useEffect(() => {
    refresh()
  }, [refresh])

  const loadUserData = useCallback(async () => {
    try {
      return (await Backend.getUserData(session?.user?.id, session?.token)).user
    } catch (error) {
      console.error('useAuth.loadUserData', error)
      throw new Error(error)
    }
  }, [session])

  const login = useCallback(async (email, password) => {
    const session = await Backend.authorize(email, password)
    saveSession(session)
    setSession(session)
    return session
  }, [])
  const logout = useCallback(async () => {
    saveSession()
    setSession()
  }, [])
  const requestPasswordReset = useCallback(async (email) => {
    return Backend.requestUserPasswordReset(email)
  }, [])
  const setNewPassword = useCallback(async (newPassword, setPasswordToken) => {
    return Backend.setUserPassword(newPassword, setPasswordToken)
  }, [])
  const changePassword = useCallback(async (oldPassword, newPassword) => {
    return Backend.changeUserPassword(oldPassword, newPassword, session?.token)
  }, [session])

  const value = {
    session,
    loading,
    error,
    refresh,
    login,
    logout,
    requestPasswordReset,
    setNewPassword,
    changePassword,
    loadUserData
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default useAuth
