import { useState, useEffect, useCallback } from 'react'

const arraySeparator = ','

const updateSearchStringInUrl = (searchString = '') => {
  window.history.pushState(
    null,
    '',
    `${window.location.pathname}${searchString.length ? '?' : ''}${searchString}`
  )
}

export const parseSearchParams = (searchString) => {
  const urlSearchParams = new URLSearchParams(searchString)
  const rawQueryParams = Object.entries(Object.fromEntries(urlSearchParams.entries()))
  const parsedQueryParams = rawQueryParams.map(([key, value]) => {
    // the last element of an array is discarded as it is always empty to mark arrays with only a single element
    if (value.includes(arraySeparator)) {
      return [key, value.split(',').slice(0, -1)]
    }
    // parse boolean value
    if (['true', 'false'].includes(value)) {
      return [key, Boolean(value)]
    }
    // try to parseInt value
    if (value.match(/^\d+$/)) {
      return [key, parseInt(value, 10)]
    }
    return [key, value]
  })
  const queryParams = Object.fromEntries(parsedQueryParams)
  return queryParams
}

export const stringifySearchParams = (searchObj) => {
  const urlSearchParams = new URLSearchParams()
  Object.entries(searchObj).forEach(([key, value]) => {
    // stringify array value (a separator is added at the end to identify arrays with only a single element)
    const preparedValue = Array.isArray(value) ? value.map(v => v.toString()).join(arraySeparator) + arraySeparator : value.toString()
    // remove empty values
    if (['', undefined, null, 0, '0'].includes(preparedValue)) {
      return
    }
    urlSearchParams.append(key, preparedValue)
  })
  return urlSearchParams.toString()
}

// subscribe to navigation events
const events = ['popstate', 'pushState', 'replaceState', 'hashchange']
function subscribe (callback) {
  for (const event of events) {
    window.addEventListener(event, callback)
  }
  return () => {
    for (const event of events) {
      window.removeEventListener(event, callback)
    }
  }
}

const useQueryParams = () => {
  const [query, setQuery] = useState(parseSearchParams(window.location.search))

  // update query object on search params change
  useEffect(() => subscribe(event => setQuery(parseSearchParams(event.currentTarget.location.search))), [setQuery])

  const updateQuery = useCallback(newQueryObj => {
    const queryString = stringifySearchParams(newQueryObj)
    // do not update without changes (also prevent infinite update loop)
    const lastQueryString = window.location.search.slice(1)
    if (queryString === lastQueryString) {
      return
    }
    updateSearchStringInUrl(queryString)
  }, [])

  return [query, updateQuery]
}

export default useQueryParams
