import { useTranslation } from 'react-i18next'
import useLanguage from '../../hooks/useLanguage'
import styled from 'styled-components'

import theme from '../../../theme'
import Divider from '../../common/Divider'
import Headline from '../../common/Headline'
import Subheadline from '../../common/Subheadline'
import Paragraph from '../../common/Paragraph'
import Section from './Section'
import LinkToSection from './LinkToSection'

import frontpageImageDe from './images/de/frontpage.jpg'
import menuImageDe from './images/de/menu.jpg'
import searchImageDe from './images/de/search.jpg'
import newDocumentImageDe from './images/de/newDocuments.jpg'
import importentDocumentsImageDe from './images/de/importentDocuments.jpg'
import directAccessToProductLinesImageDe from './images/de/directAccessToProductLines.jpg'
import documentsImageDe from './images/de/documents.jpg'
import documentsFilterImageDe from './images/de/documentsFilter.jpg'
import bookmarkDocumentsImageDe from './images/de/bookmarkDocuments.jpg'
import downloadDocumentsImageDe from './images/de/downloadDocuments.jpg'
import infoButtonImageDe from './images/de/infoButton.jpg'

import frontpageImageEn from './images/en/frontpage.jpg'
import menuImageEn from './images/en/menu.jpg'
import searchImageEn from './images/en/search.jpg'
import newDocumentImageEn from './images/en/newDocuments.jpg'
import importentDocumentsImageEn from './images/en/importentDocuments.jpg'
import directAccessToProductLinesImageEn from './images/en/directAccessToProductLines.jpg'
import documentsImageEn from './images/en/documents.jpg'
import documentsFilterImageEn from './images/en/documentsFilter.jpg'
import bookmarkDocumentsImageEn from './images/en/bookmarkDocuments.jpg'
import downloadDocumentsImageEn from './images/en/downloadDocuments.jpg'
import infoButtonImageEn from './images/en/infoButton.jpg'

const helpImages = {
  de: {
    frontpage: frontpageImageDe,
    menu: menuImageDe,
    search: searchImageDe,
    newDocument: newDocumentImageDe,
    importentDocuments: importentDocumentsImageDe,
    directAccessToProductLines: directAccessToProductLinesImageDe,
    documents: documentsImageDe,
    documentsFilter: documentsFilterImageDe,
    bookmarkDocuments: bookmarkDocumentsImageDe,
    downloadDocuments: downloadDocumentsImageDe,
    infoButton: infoButtonImageDe
  },
  en: {
    frontpage: frontpageImageEn,
    menu: menuImageEn,
    search: searchImageEn,
    newDocument: newDocumentImageEn,
    importentDocuments: importentDocumentsImageEn,
    directAccessToProductLines: directAccessToProductLinesImageEn,
    documents: documentsImageEn,
    documentsFilter: documentsFilterImageEn,
    bookmarkDocuments: bookmarkDocumentsImageEn,
    downloadDocuments: downloadDocumentsImageEn,
    infoButton: infoButtonImageEn
  }
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${theme.spacing * 5}px;
  padding-left: ${theme.spacing * 14}px;
  padding-right: ${theme.spacing * 14}px;
  gap: ${theme.spacing * 3}px;
  background-color: ${theme.palette.shade[0]};
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing * 2}px;
`

// @TODO add final content from https://hpm.agency/p/rea/040-pm/-/issues/46
const Help = () => {
  const { language } = useLanguage()
  const { t } = useTranslation()
  return (
    <StyledContainer>
      <Headline>{t('help.headline.title')}</Headline>
      <Divider />
      <Section>
        <Paragraph>
          {t('help.description')}
        </Paragraph>
        <LinkContainer>
          <LinkToSection targetId='frontpage'>{t('help.frontpage.title')}</LinkToSection>
          <LinkToSection targetId='menu'>{t('help.menu.title')}</LinkToSection>
          <LinkToSection targetId='search'>{t('help.search.title')}</LinkToSection>
          <LinkToSection targetId='newDocuments'>{t('help.newDocuments.title')}</LinkToSection>
          <LinkToSection targetId='directEntryToProductLines'>{t('help.directEntryToProductLines.title')}</LinkToSection>
          <LinkToSection targetId='importentDocuments'>{t('help.importentDocuments.title')}</LinkToSection>
          <LinkToSection targetId='documents'>{t('help.documents.title')}</LinkToSection>
          <LinkToSection targetId='documentFilter'>{t('help.documentFilter.title')}</LinkToSection>
          <LinkToSection targetId='bookmarkDocuments'>{t('help.bookmarkDocuments.title')}</LinkToSection>
          <LinkToSection targetId='downloadDocuments'>{t('help.downloadDocuments.title')}</LinkToSection>
          <LinkToSection targetId='infoButton'>{t('help.infoButton.title')}</LinkToSection>
        </LinkContainer>
      </Section>
      <Divider />
      <Section
        id='frontpage'
        imagePosition='left'
        imageSrc={helpImages[language].frontpage}
        imageAlt={t('help.frontpage.imgAlt')}
      >
        <Subheadline>
          {t('help.frontpage.title')}
        </Subheadline>
        <Paragraph>
          {t('help.frontpage.description')}
        </Paragraph>
      </Section>
      <Section
        id='menu'
        imagePosition='right'
        imageSrc={helpImages[language].menu}
        imageAlt={t('help.menu.imgAlt')}
      >
        <Subheadline>
          {t('help.menu.title')}
        </Subheadline>
        <Paragraph>
          {t('help.menu.description')}
        </Paragraph>
      </Section>
      <Section
        id='search'
        imagePosition='left'
        imageSrc={helpImages[language].search}
        imageAlt={t('help.search.imgAlt')}
      >
        <Subheadline>
          {t('help.search.title')}
        </Subheadline>
        <Paragraph>
          {t('help.search.description')}
        </Paragraph>
      </Section>
      <Section
        id='newDocuments'
        imagePosition='right'
        imageSrc={helpImages[language].newDocument}
        imageAlt={t('help.newDocuments.imgAlt')}
      >
        <Subheadline>
          {t('help.newDocuments.title')}
        </Subheadline>
        <Paragraph>
          {t('help.newDocuments.description')}
        </Paragraph>
      </Section>
      <Section
        id='directEntryToProductLines'
        imagePosition='left'
        imageSrc={helpImages[language].directAccessToProductLines}
        imageAlt={t('help.directEntryToProductLines.imgAlt')}
      >
        <Subheadline>
          {t('help.directEntryToProductLines.title')}
        </Subheadline>
        <Paragraph>
          {t('help.directEntryToProductLines.description')}
        </Paragraph>
      </Section>
      <Section
        id='importentDocuments'
        imagePosition='right'
        imageSrc={helpImages[language].importentDocuments}
        imageAlt={t('help.importentDocuments.imgAlt')}
      >
        <Subheadline>
          {t('help.importentDocuments.title')}
        </Subheadline>
        <Paragraph>
          {t('help.importentDocuments.description')}
        </Paragraph>
      </Section>
      <Section
        id='documents'
        imagePosition='left'
        imageSrc={helpImages[language].documents}
        imageAlt={t('help.documents.imgAlt')}
      >
        <Subheadline>
          {t('help.documents.title')}
        </Subheadline>
        <Paragraph>
          {t('help.documents.description')}
        </Paragraph>
      </Section>
      <Section
        id='documentFilter'
        imagePosition='right'
        imageSrc={helpImages[language].documentsFilter}
        imageAlt={t('help.documentFilter.imgAlt')}
      >
        <Subheadline>
          {t('help.documentFilter.title')}
        </Subheadline>
        <Paragraph>
          {t('help.documentFilter.description')}
        </Paragraph>
      </Section>
      <Section
        id='bookmarkDocuments'
        imagePosition='left'
        imageSrc={helpImages[language].bookmarkDocuments}
        imageAlt={t('help.bookmarkDocuments.imgAlt')}
      >
        <Subheadline>
          {t('help.bookmarkDocuments.title')}
        </Subheadline>
        <Paragraph>
          {t('help.bookmarkDocuments.description')}
        </Paragraph>
      </Section>
      <Section
        id='downloadDocuments'
        imagePosition='right'
        imageSrc={helpImages[language].downloadDocuments}
        imageAlt={t('help.downloadDocuments.imgAlt')}
      >
        <Subheadline>
          {t('help.downloadDocuments.title')}
        </Subheadline>
        <Paragraph>
          {t('help.downloadDocuments.description')}
        </Paragraph>
      </Section>
      <Section
        id='infoButton'
        imagePosition='left'
        imageSrc={helpImages[language].infoButton}
        imageAlt={t('help.infoButton.imgAlt')}
      >
        <Subheadline>
          {t('help.infoButton.title')}
        </Subheadline>
        <Paragraph>
          {t('help.infoButton.description')}
        </Paragraph>
      </Section>
    </StyledContainer>
  )
}

export default Help
