import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'

import theme from '../../../../theme'

const IconButtonStyled = styled(IconButton)`
  color: ${theme.palette.neutral[400]} !important;
  background-color: ${theme.palette.shade[0]} !important;
  border-radius: 6px !important;
  margin-right: ${theme.spacing / 2}px !important;
  &:hover {
    color: ${theme.palette.primary.main} !important;
    background-color: ${theme.palette.shade['0Transparent']} !important;
  }
`

const IconButtonContained = ({ onClick: handleClick, href, icon: Icon, ...restProps }) => {
  const buttonProps = {
    component: href ? 'a' : undefined,
    href,
    onClick: handleClick
  }

  return (
    <IconButtonStyled {...buttonProps}>
      <Icon />
    </IconButtonStyled>
  )
}

export default IconButtonContained
