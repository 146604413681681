import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import theme from '../../../../theme'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const LogoImage = styled.img`
  height: 22px;
`

const StyledText = styled.div`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.p.fontSize};
  color: ${theme.palette.neutral[600]};
  text-align: left;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled(Grid)`
  &:hover {
    box-shadow: ${theme.shadow.cardHover};
    background-color: ${theme.palette.shade['0Transparent085']};
  }
  &:hover div {
    color: ${theme.palette.neutral[700]};
  }
  &:hover span {
    color: ${theme.palette.primary.main};
  }
  margin-top: ${theme.spacing * 3}px;
  height: 310px;
  padding: ${theme.spacing * 5}px;
  padding-top: ${theme.spacing * 6}px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: ${theme.shadow.card};
  background-color: ${theme.palette.shade['0Transparent065']};
  cursor: pointer;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  width: 24px !important;
  height: 24px !important;
  position: relative;
  top: 8px;
`

const StyledLinkGrid = styled(Grid)`
  display: flex;
  flex-flow: column wrap !important;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  color: ${theme.palette.primary[500]};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.pSmall.fontSize};
  line-height: 18px;
  text-decoration: none;
`

const StyledDescriptionItem = styled.div`
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  line-height: 22px;
  color: ${theme.palette.primary[600]}
  font-weight: ${theme.typography.p.fontWeight};
  font-size: ${theme.typography.p.fontSize};
  display:flex;
  align-items: center;
`

const StyledContentItem = styled(Grid)`
  padding-right: ${theme.spacing * 3}px;
`

const ProductlineItem = ({
  imageSrc,
  link,
  count,
  children,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <StyledContentItem item xs={3} {...restProps}>
      <Link href={link}>
        <StyledContainer
          container
          direction='column'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <Grid item xs={4}><LogoImage src={imageSrc} /></Grid>
          <StyledText item xs={4}><StyledDescriptionItem item xs={12}>{children}</StyledDescriptionItem></StyledText>
          <StyledLinkGrid item xs={4}><span>{t('dashboard.documentArtCount', { count })} <StyledArrowForwardIcon /></span></StyledLinkGrid>
        </StyledContainer>
      </Link>
    </StyledContentItem>
  )
}

export default ProductlineItem
