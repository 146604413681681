import { useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import styled from 'styled-components'

import { Viewer, SpecialZoomLevel, LocalizationContext } from '@react-pdf-viewer/core'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import pdfViewerLocaleDe from '@react-pdf-viewer/locales/lib/de_DE.json'
import pdfViewerLocaleEn from '@react-pdf-viewer/locales/lib/en_US.json'

import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

import FullscreenIcon from '@mui/icons-material/Fullscreen'

import theme from '../../../../theme'
import { getDownloadLink, getThumbnailLink } from '../../../common/Backend'

const ViewerContainer = styled.div`
  margin-top: ${theme.spacing * 2}px;
  height: 80vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: ${theme.spacing * 1}px;
`

const PdfViewerContainer = styled(ViewerContainer)`
  padding-bottom: ${theme.spacing * 4}px;
`

const VideoViewerContainer = styled(ViewerContainer)`
  height: auto;
`

const VideoViewerContent = styled.video`
  width: 100%;
`

const PdfViewerToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: ${theme.spacing}px;
`

const PdfViewerToolbarSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: center;
  gap: ${theme.spacing}px;
`

const ImageViewerContent = styled.img`
  object-fit: contain;
  background-color: ${theme.palette.background.light};
  height: 100%;
  width: 100%;
`

const FullscreenButton = styled(IconButton)`
  margin-top: -48px !important;
  margin-right: 16px !important;
  background-color: white !important;
`

const overrideGermanTranslations = translations => {
  // some translations are too literal, so they are fixed here
  // @TODO this can be removed when https://github.com/react-pdf-viewer/locales/pull/5 is merged
  translations.core.askingPassword.submit = 'Öffnen'
  translations.core.wrongPassword.submit = 'Öffnen'
  translations.fullScreen.enterFullScreen = 'Vollbildmodus starten'
  translations.pageNavigation.enterPageNumber = 'Seitenzahl eingeben'
  translations.scrollMode.wrappedScrolling = 'Freies Scrollen'
  translations.search.enterToSearch = 'Suchtext eingeben'
  translations.search.nextMatch = 'Nächster Treffer'
  translations.search.previousMatch = 'Vorheriger Treffer'
  return translations
}

const DocumentViewer = ({ doc, language }) => {
  const { session } = useAuth()
  const pdfViewerLocaleDeWithOverrides = overrideGermanTranslations(pdfViewerLocaleDe)
  const [l10n, setL10n] = useState(language === 'de' ? pdfViewerLocaleDeWithOverrides : pdfViewerLocaleEn)
  const localizationContext = { l10n, setL10n }

  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar } = toolbarPluginInstance

  const viewerFileUrl = getDownloadLink(doc.id, { token: session.token, registerDownload: false })

  if (doc.mimetype === 'application/pdf') {
    return (
      <PdfViewerContainer>
        <LocalizationContext.Provider value={localizationContext}>
          <Toolbar>{props => {
            const {
              ShowSearchPopover,
              GoToNextPage,
              CurrentPageInput,
              NumberOfPages,
              GoToPreviousPage,
              ZoomIn,
              Zoom,
              ZoomOut,
              EnterFullScreen
            } = props
            return (
              <PdfViewerToolbar>
                <PdfViewerToolbarSection justify='flex-start'>
                  <ShowSearchPopover />
                  <GoToNextPage />
                  <CurrentPageInput />
                  <span>/</span>
                  <NumberOfPages />
                  <GoToPreviousPage />
                </PdfViewerToolbarSection>
                <PdfViewerToolbarSection>
                  <ZoomIn />
                  <Zoom />
                  <ZoomOut />
                </PdfViewerToolbarSection>
                <PdfViewerToolbarSection justify='flex-end'>
                  <EnterFullScreen />
                </PdfViewerToolbarSection>
              </PdfViewerToolbar>
            )
          }}
          </Toolbar>
          <Viewer
            fileUrl={viewerFileUrl}
            plugins={[toolbarPluginInstance]}
            defaultScale={SpecialZoomLevel.ActualSize}
            renderLoader={percentages => (
              <CircularProgress />
            )}
          />
        </LocalizationContext.Provider>
      </PdfViewerContainer>
    )
  }
  if (doc.mimetype.startsWith('image/')) {
    return (
      <ViewerContainer>
        <ImageViewerContent id='active-image-viewer' src={viewerFileUrl} />
        <FullscreenButton onClick={() => {
          const elem = document.getElementById('active-image-viewer')
          if (typeof elem.requestFullscreen === 'function') {
            elem.requestFullscreen()
            return
          }
          if (typeof elem.webkitRequestFullscreen === 'function') {
            elem.webkitRequestFullscreen()
            return
          }
          if (typeof elem.msRequestFullscreen === 'function') {
            elem.msRequestFullscreen()
            return
          }
          console.error('could not open element fullscreen. browser does not support this API.')
        }}
        ><FullscreenIcon />
        </FullscreenButton>
      </ViewerContainer>
    )
  }
  if (doc.mimetype.startsWith('video/')) {
    return (
      <VideoViewerContainer>
        <VideoViewerContent controls>
          <source src={viewerFileUrl} />
        </VideoViewerContent>
      </VideoViewerContainer>
    )
  }

  // handle documents that can't be directly viewed
  const viewerPreviewFileUrl = getThumbnailLink(doc.id)
  return (
    <ViewerContainer>
      <ImageViewerContent src={viewerPreviewFileUrl} />
    </ViewerContainer>
  )
}

export default DocumentViewer
