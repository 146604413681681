import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Pagination from '@mui/material/Pagination'

import theme from '../../../theme'
import useDocuments from '../../hooks/useDocuments'
import useDashboard from '../../hooks/useDashboard'
import useQueryParams from '../../hooks/useQueryParams'
import DocumentList from './DocumentList'
import SearchInput from './SearchInput'
import TagFilter from './TagFilter'
import scrollToTop from '../../common/scrollToTop'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${theme.spacing * 5}px;
  gap: ${theme.spacing * 5}px;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing * 5}px;
  filter: ${({ $loading }) => $loading ? 'blur(1px)' : 'none'};
  pointer-events: ${({ $loading }) => $loading ? 'none' : 'all'};
  width: 100%;
`

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${theme.palette.neutral[500]};
  gap: ${theme.spacing}px;
`

const presetFilterKeys = ['new', 'favorites', 'lastViewed']

const queryTimeoutMs = 2 * 1000

const areTagIdArraysEqual = (a, b) => {
  const [aTemp, bTemp] = [[...a], [...b]]
  aTemp.sort()
  bTemp.sort()
  return aTemp.join('|') === bTemp.join('|')
}

const Search = () => {
  const { t } = useTranslation()

  const { refresh: refreshDashboard } = useDashboard()
  const { documents, total, loading, error, applyFilter, filter, defaultFilterParams, contentReady, refresh } = useDocuments()
  const [queryParams] = useQueryParams()

  const [page, setPage] = useState(1)

  const queryTimeout = useRef()

  // handle preset filter routes
  let hasPresetFilter = false
  let documentListTitle
  let noResultsTranslationKey
  presetFilterKeys.forEach(presetFilterKey => {
    if (Object.keys(queryParams).includes(presetFilterKey)) {
      hasPresetFilter = true
      documentListTitle = t(`search.presetFilterViewTitles.${presetFilterKey}`)
      noResultsTranslationKey = `search.noResultsText.${presetFilterKey}`
    }
  })

  useEffect(() => {
    scrollToTop()
  }, [])

  // update paging when filter changes
  useEffect(() => {
    if (!filter) {
      return
    }
    if (filter.offset) {
      const pageFromFilter = parseInt(Math.floor(filter.offset / defaultFilterParams.limit), 10)
      setPage(pageFromFilter + 1)
      return
    }
    setPage(1)
  }, [filter, setPage, defaultFilterParams.limit])

  // apply query
  const applyQuery = query => {
    if (query === filter.query) {
      return
    }
    applyFilter(currentFilter => {
      delete currentFilter.offset
      return {
        ...currentFilter,
        query
      }
    })
  }

  // input query is applied 2 seconds after last change
  const handleSearchInputChange = (query, origin) => {
    if (typeof query === 'undefined') {
      return
    }
    if (queryTimeout.current) {
      clearTimeout(queryTimeout.current)
    }
    // immediately apply query filter on clear & enter
    if (['clear', 'enter'].includes(origin)) {
      applyQuery(query)
      return
    }
    queryTimeout.current = setTimeout(() => applyQuery(query), queryTimeoutMs)
  }
  useEffect(() => {
    // cleanup remaining timeouts when component is unmounted
    return () => {
      if (queryTimeout.current) {
        clearTimeout(queryTimeout.current)
      }
    }
  }, [])

  const handlePageChange = (event, newPage) => {
    applyFilter((currentFilter = {}) => {
      const newOffset = (newPage - 1) * defaultFilterParams.limit
      if (newOffset === 0) {
        const { offset, ...currentFilterWithoutOffset } = currentFilter
        return {
          ...currentFilterWithoutOffset
        }
      }
      return {
        ...currentFilter,
        offset: newOffset
      }
    })
    scrollToTop()
  }

  const handleTagFilterChange = selectedTags => {
    if ((!filter?.tags?.length && !selectedTags?.length) || (filter?.tags && areTagIdArraysEqual(filter.tags, selectedTags))) {
      return
    }
    applyFilter((currentFilter = {}) => {
      const { offset, ...currentFilterWithoutOffset } = currentFilter
      return ({
        ...currentFilterWithoutOffset,
        tags: selectedTags.length ? selectedTags : undefined
      })
    })
  }
  const updateDocumentList = () => {
    refresh()
    refreshDashboard()
  }

  return (
    <StyledContainer>
      {!hasPresetFilter &&
        <FilterContainer $loading={loading}>
          <SearchInput
            initValue={filter?.query}
            placeholderText={t('search.placeholderText')}
            onChange={handleSearchInputChange}
            style={{ width: '67%' }}
          />
          <TagFilter
            onChange={handleTagFilterChange}
          />
        </FilterContainer>}
      <DocumentList
        title={documentListTitle}
        noResultsTranslationKey={noResultsTranslationKey}
        documentCount={total}
        documents={documents}
        loading={loading || !contentReady}
        error={error}
        showDivider={hasPresetFilter}
        update={updateDocumentList}
      />
      {!loading && contentReady && !error && !!total && (total > defaultFilterParams.limit) &&
        <PaginationContainer>
          <Pagination count={Math.ceil(total / defaultFilterParams.limit)} page={page} onChange={handlePageChange} color='primary' />
        </PaginationContainer>}
    </StyledContainer>
  )
}

export default Search
