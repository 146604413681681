import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useLanguage from '../../hooks/useLanguage'
import styled from 'styled-components'
import theme from '../../../theme'
import InfoLink from '../../common/InfoLink'
import Typography from '@mui/material/Typography'

const CenterTypography = styled(Typography)`
  text-align: center;
  height: auto;
`

const StyledContainer = styled.div`
  margin-top: ${theme.spacing * 5}px;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing * 3}px;
`

const InfoButton = styled.a`
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  color: ${theme.palette.shade[0]};
  &:hover {
    color: ${theme.palette.shade[0]};
  }
  &:visited {
    color: ${theme.palette.shade[0]};
  }
  &:link {
    color: ${theme.palette.shade[0]};
  }
  &:active {
    color: ${theme.palette.shade[0]};
  }
`

const Footer = ({ color = theme.palette.shade[0], ...restProps }) => {
  const { t } = useTranslation()
  const { changeLanguage, language } = useLanguage()

  const handleLanguageChange = useCallback(() => {
    changeLanguage(language === 'de' ? 'en' : 'de')
  }, [language, changeLanguage])

  return (
    <StyledContainer {...restProps}>
      <InfoLink color={color} href={t('footer.imprint.link')} target='_blank'>
        {t('footer.imprint.linkText')}
      </InfoLink>
      <InfoLink color={color} href={`mailto:${t('footer.contact.linkEmailAddress')}?subject=${encodeURI(t('footer.contact.linkEmailSubject'))}`}>
        {t('footer.contact.linkText')}
      </InfoLink>
      <InfoLink color={color} href={t('footer.privacy.link')} target='_blank'>
        {t('footer.privacy.linkText')}
      </InfoLink>
      <CenterTypography variant='pSmall'>
        <InfoButton color={color} onClick={handleLanguageChange}>
          {language === 'de' ? 'English' : 'Deutsch'}
        </InfoButton>
      </CenterTypography>

    </StyledContainer>
  )
}

export default Footer
