import { Route, Switch, Redirect } from 'wouter'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import styled from 'styled-components'

import useAuth from '../hooks/useAuth'

import Theme from '../../theme'

import Layout from './Layout'
import Loading from './Loading'
import AuthLayout from './Auth/Layout'
import Login from './Auth/Login'
import ChangePassword from './Auth/ChangePassword'
import ForgotPassword from './Auth/ForgotPassword'
import Dashboard from './Dashboard'
import Search from './Search'
import Help from './Help'
import Profile from './Profile'

const AppStyleBaseline = styled(CssBaseline)`
  overflow-x: hidden !important;
`

const withLayout = (Component, Layout) => props => <Layout><Component {...props} /></Layout>

function App () {
  const { session, loading } = useAuth()
  const theme = createTheme(Theme)
  return (
    <ThemeProvider theme={theme}>
      <AppStyleBaseline />
      <Switch>
        {loading && <Route component={Loading} />}
        {!loading && !session &&
          <>
            <Route path='/login' component={withLayout(Login, AuthLayout)} />
            <Route path='/firstlogin' component={withLayout(ChangePassword, AuthLayout)} />
            <Route path='/resetpassword' component={withLayout(ChangePassword, AuthLayout)} />
            <Route path='/forgotpassword' component={withLayout(ForgotPassword, AuthLayout)} />
            <Route component={() => <Redirect to='/login' />} />
          </>}
        {!loading && session &&
          <>
            <Route path='/' component={withLayout(Dashboard, Layout)} />
            <Route path='/dashboard' component={() => <Redirect to='/' />} />
            <Route path='/search' component={withLayout(Search, Layout)} />
            <Route path='/help' component={withLayout(Help, Layout)} />
            <Route path='/profile' component={withLayout(Profile, Layout)} />
            <Route component={() => <Redirect to='/' />} />
          </>}
      </Switch>
    </ThemeProvider>
  )
}

export default App
