import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLocation } from 'wouter'

import theme from '../../../theme'
import useDashboard from '../../hooks/useDashboard'
import useLanguage from '../../hooks/useLanguage'
import useDocuments from '../../hooks/useDocuments'

import SearchInput from '../Search/SearchInput'
import DocumentList from '../Search/DocumentList'
import DocumentArtList from './DocumentArtList'
import ProductlineList from './ProductlineList'
import scrollToTop from '../../common/scrollToTop'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${theme.spacing * 5}px;
  gap: ${theme.spacing * 5}px;
`

const newDocumentslimit = 6

const Dashboard = () => {
  const { t } = useTranslation()
  const { linkWithLangParam } = useLanguage()
  const [location, setLocation] = useLocation()

  const { documents, documentArts, productlines, total, loading, error, refresh } = useDashboard()
  const { setLoading, applyFilter } = useDocuments()

  // handle preset filter routes
  const documentListTitle = t('header.navbarItems.newDocuments')
  const documentArtListTitle = t('dashboard.documentArts')
  const noResultsTranslationKey = t('search.noResultsText.new')

  const handleSearchInputChange = useCallback((query, origin) => {
    if (typeof query === 'undefined') {
      return
    }
    // immediately apply query filter on clear & enter
    if (['enter'].includes(origin)) {
      setLoading(true)
      setLocation(linkWithLangParam(`/search?query=${query}`))
    }
  }, [setLocation, linkWithLangParam, setLoading])

  useEffect(() => {
    scrollToTop()
  }, [])

  // this is a workaround to prevent search views from getting stuck in loading state
  useEffect(() => {
    applyFilter({ language: 'de' })
  }, [applyFilter])

  const updateDocumentList = () => {
    refresh()
  }

  useEffect(updateDocumentList, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledContainer>
      <SearchInput
        placeholderText={t('search.placeholderText')}
        style={{ width: '67%' }}
        onChange={handleSearchInputChange}
      />
      <DocumentList
        title={documentListTitle}
        noResultsTranslationKey={noResultsTranslationKey}
        documentCount={total}
        documents={documents}
        loading={loading}
        error={error}
        limit={newDocumentslimit}
        update={updateDocumentList}
      />
      <ProductlineList
        productlines={productlines}
      />
      <DocumentArtList
        title={documentArtListTitle}
        documentArts={documentArts}
      />
    </StyledContainer>
  )
}

export default Dashboard
