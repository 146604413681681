import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import NoEntriesIcon from './NoEntries.svg'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from '@mui/material/CircularProgress'
import ReplayIcon from '@mui/icons-material/Replay'

import theme from '../../../../theme'
import DocumentItem from '../DocumentItem'
import Divider from '../../../common/Divider'

import useLanguage from '../../../hooks/useLanguage'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${theme.spacing * 4}px;
`

const Headline = styled(Typography).attrs({
  variant: 'h7'
})`
  color: ${theme.palette.primary.main};
`

const DocumentCount = styled(Typography).attrs({
  variant: 'pSmall'
})`
  color: ${theme.palette.neutral[600]};
`

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  color: ${theme.palette.neutral[500]};
  gap: ${({ vertical }) => theme.spacing * (vertical ? 5 : 1)}px;
  padding-top: ${theme.spacing * 5}px;
  padding-bottom: ${theme.spacing * 5}px;
`

const NoEntries = styled.img.attrs({
  src: NoEntriesIcon
})``

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2147483647;
  gap: ${theme.spacing * 1}px;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing * 2}px;
  align-items: center;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  width: 24px !important;
  height: 24px !important;
  position: relative;
  top: 8px;
`

const StyledLink = styled(Link)`
  color: #0453a2;
  font-family: Open Sans;
  font-size: 13.3px;
  text-decoration: none;
`

const StyledDivider = styled(Divider)`
  margin-bottom: ${theme.spacing * 3}px;
`

const DocumentList = ({
  title,
  documentCount,
  documents = [],
  loading,
  error,
  noResultsTranslationKey,
  limit,
  showDivider = false,
  update: updateDocumentList,
  ...restProps
}) => {
  const { t } = useTranslation()
  const { language, resolvedLanguage } = useLanguage()

  const limiter = (docs, limit) => limit ? docs.slice(0, limit) : docs

  const newDocumentsLink = `/search?${language !== resolvedLanguage ? `language=${language}&` : ''}new=true`

  return (
    <Container {...restProps}>
      <ListHeader>
        <Headline>{title}</Headline>
        {!loading && !error && !limit && <DocumentCount>{t('search.documentCount', { count: documentCount !== undefined ? documentCount : documents.length })}</DocumentCount>}
        {!loading && !error && !!limit && <StyledLink href={newDocumentsLink}>{t('search.documentCountLink', { count: documentCount !== undefined ? documentCount : documents.length })} <StyledArrowForwardIcon /></StyledLink>}
      </ListHeader>
      {showDivider && !loading && !error && <StyledDivider />}
      {loading &&
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>}
      {!loading && !error && !documents?.length &&
        <NoResultsContainer vertical={!!noResultsTranslationKey}>
          {noResultsTranslationKey ? <NoEntries /> : <SearchOutlinedIcon />}
          <Typography>{t(noResultsTranslationKey || 'search.noResultsText.default')}</Typography>
        </NoResultsContainer>}
      {!loading && error &&
        <ErrorContainer>
          <NoResultsContainer>
            <ErrorIcon />
            <Typography>{t('search.errorText')}</Typography>
          </NoResultsContainer>
          <Button
            variant='text'
            color='primary'
            onClick={() => {
              // reload application
              window.location.href = String(window.location.href)
            }}
            endIcon={<ReplayIcon />}
          >
            {t('search.buttonTryAgain')}
          </Button>
        </ErrorContainer>}
      {!loading && !error &&
        <Grid columnSpacing={3} rowSpacing={3} container>
          {limiter(documents, limit).map(doc => (
            <DocumentItem
              key={doc.id}
              doc={doc}
              updateDocumentList={updateDocumentList}
            />
          ))}
        </Grid>}
    </Container>
  )
}

export default DocumentList
