import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import useAuth from './useAuth'
import useLanguage from './useLanguage'
import Backend from '../common/Backend'

const timeout = timeout => new Promise(resolve => setTimeout(resolve, timeout))

const loadDashboardStats = async (filter) => {
  // @TODO implement loading Dashboard from api
  await timeout(200)
  return []
}

const DashboardContext = createContext()

export const useDashboard = () => {
  const context = useContext(DashboardContext)

  if (context === undefined) {
    throw new Error('useDashboard must be used within an DashboardProvider')
  }

  return context
}

export const DashboardProvider = ({ children }) => {
  const { session } = useAuth()
  const [dashboardStats, setDashboardStats] = useState()
  const [documents, setDocuments] = useState([])
  const [documentArts, setDocumentArts] = useState([])
  const [productlines, setProductlines] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const { language } = useLanguage()

  const loadDocuments = useCallback(async () => {
    if (!session?.user) {
      return
    }
    const { newestDocuments, stats } = await Backend.getDashboardData({ user: session.user, language }, session.token)

    const documentsWithNewAttribute = newestDocuments
      .map(d => ({
        ...d,
        isNew: true
      }))

    setDocuments(documentsWithNewAttribute)
    setDashboardStats(stats)
    setDocumentArts(stats.docartCounts)
    setProductlines(stats.brandCounts)
    setTotal(stats.newDocumentsCount)
  }, [session, language])

  const refresh = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      const loadedDashboardStats = await loadDashboardStats()
      setDashboardStats(loadedDashboardStats)
      await loadDocuments()
    } catch (error) {
      console.error('useDashboard', error)
      setError(error.message)
    }
    setLoading(false)
  }, [loadDocuments, setLoading, setError, setDashboardStats])

  useEffect(() => {
    refresh()
  }, [refresh])

  const value = {
    documents,
    documentArts,
    productlines,
    dashboardStats,
    total,
    loading,
    error,
    refresh
  }

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export default useDashboard
