import styled from 'styled-components'
import theme from '../../theme'

const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => color};
`

const Divider = ({
  color = theme.palette.neutral[200],
  ...restProps
}) => {
  return (
    <Container color={color} {...restProps} />
  )
}

export default Divider
