import { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import styled from 'styled-components'

import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import Star from '@mui/icons-material/Star'

import theme from '../../../../theme'
import { getDownloadLink } from '../../../common/Backend'
import { buildDownloadFilename } from '../../../common/format'
import useFavorites from '../../../hooks/useFavorites'
import DocumentViewer from './DocumentViewer'

const ModalContainer = styled(Box)`
  padding-left: ${theme.spacing * 9}px;
  padding-right: ${theme.spacing * 9}px;
  padding-top: ${theme.spacing * 5}px;
  padding-bottom: ${theme.spacing * 2}px;
  display: flex;
  justify-content: center;
`

const ModalContent = styled.div`
  position: relative;
  max-width: ${theme.maxContentWidth};
  width: 100%;
  padding: ${theme.spacing * 3}px;
  border-radius: ${theme.spacing * 2}px;
  background-color: ${theme.palette.background.light};
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 0 !important;
  right: -${theme.spacing * 7}px !important;
  color: ${theme.palette.shade[0]} !important;
`
const DocumentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DocumentTitle = styled.header`
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${theme.typography.h7.fontWeight};
  font-size: ${theme.typography.h7.fontSize};
  line-height: 26px;
  height: 26px;
  color: ${theme.palette.primary.main};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing * 2}px;
`

const InfoPopover = styled(Typography)`
  color: ${theme.palette.neutral[600]} !important;
  padding: ${theme.spacing}px;
`

const DocumentDetailModal = ({ open, doc, onClose: handleClose, ...restProps }) => {
  const { session } = useAuth()
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage === 'de' ? 'de' : 'en'

  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState(null)
  const infoPopoverOpen = Boolean(infoPopoverAnchorEl)
  const handleInfoPopoverOpen = useCallback((event) => {
    setInfoPopoverAnchorEl(event.currentTarget)
  }, [setInfoPopoverAnchorEl])
  const handleInfoPopoverClose = useCallback(() => {
    setInfoPopoverAnchorEl(null)
  }, [setInfoPopoverAnchorEl])

  const stopEventPropagation = useCallback(event => event.stopPropagation(), [])

  const { favorites, add: addFavorite, remove: removeFavorite } = useFavorites()

  const [isFavorited, setIsFavorited] = useState(false)
  useEffect(() => {
    setIsFavorited(favorites.find(favorite => favorite.documentId === doc.id))
  }, [favorites, doc?.id])

  const handleToggleFavorite = useCallback(event => {
    stopEventPropagation(event)
    return isFavorited ? removeFavorite(doc.id) : addFavorite(doc.id)
  }, [doc, isFavorited, removeFavorite, addFavorite, stopEventPropagation])

  const downloadFilename = useMemo(() => buildDownloadFilename(doc, language), [doc, language])

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClick={handleClose}
      {...restProps}
    >
      <ModalContainer>
        <ModalContent onClick={stopEventPropagation}>
          <CloseButton onClick={handleClose}><CloseIcon /></CloseButton>
          <DocumentHeader>
            <DocumentTitle>{doc[`title_${language}`]}</DocumentTitle>
            <ButtonContainer>
              <Button
                variant='outlined'
                size='small'
                startIcon={isFavorited ? <Star /> : <StarOutlineOutlinedIcon />}
                onClick={handleToggleFavorite}
              >
                {t('documentViewer.buttonFavorite')}
              </Button>
              {doc[`note_${language}`] &&
                <>
                  <Button
                    variant='outlined'
                    size='small'
                    startIcon={<InfoIcon />}
                    onClick={handleInfoPopoverOpen}
                  >
                    {t('documentViewer.buttonInformation')}
                  </Button>
                  <Popover
                    id='document-info-popover'
                    open={infoPopoverOpen}
                    anchorEl={infoPopoverAnchorEl}
                    onClose={handleInfoPopoverClose}
                    anchorOrigin={{
                      vertical: theme.spacing * 5,
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <InfoPopover>{doc[`note_${language}`]}</InfoPopover>
                  </Popover>
                </>}
              <Button
                variant='outlined'
                size='small'
                startIcon={<FileDownloadOutlinedIcon />}
                href={getDownloadLink(doc.id, { token: session.token, downloadFilename })}
              >
                {t('documentViewer.buttonDownload')}
              </Button>
            </ButtonContainer>
          </DocumentHeader>
          <DocumentViewer doc={doc} language={language} />
        </ModalContent>
      </ModalContainer>
    </Modal>
  )
}

export default DocumentDetailModal
