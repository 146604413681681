import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'

import MailOutline from '@mui/icons-material/MailOutline'
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined'

import InfoLink from '../../common/InfoLink'
import Header from '../../common/Header'
import Result from '../../common/Result'
import useAuth from '../../hooks/useAuth'
import Validator from '../../common/validator'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { requestPasswordReset } = useAuth()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState()
  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)

  const clearValidation = useCallback(() => {
    setEmailError()
  }, [setEmailError])

  const validate = useCallback(() => {
    clearValidation()
    if (email && !Validator.email(email)) {
      setEmailError(t('forgotpassword.emailInput.invalid'))
      return false
    }
    return true
  }, [t, clearValidation, email, setEmailError])

  useEffect(() => {
    clearValidation()
  }, [clearValidation, email])

  const handleForgotPassword = useCallback(async (event) => {
    event.preventDefault()

    if (!validate()) {
      return false
    }

    setLoading(true)
    try {
      await requestPasswordReset(email)
      setResult(true)
    } catch (error) {
      console.error(error)
      setResult(error.message)
    }
    setLoading(false)
  }, [validate, requestPasswordReset, email, setResult])

  // error result
  if (result && result !== true) {
    return (
      <Result
        title={t('forgotpassword.title')}
        description={t('errorResult.description')}
        buttonText={t('errorResult.actionRetry')}
        buttonAction={() => setResult(false)}
        buttonSecondaryText={t('errorResult.actionBackToLogin')}
        buttonSecondaryLink='/login'
        Icon={WarningAmberOutlined}
      />
    )
  }

  // success result
  if (result && result === true) {
    return (
      <Result
        title={t('forgotpassword.confirmation.title')}
        description={t('forgotpassword.confirmation.description')}
        buttonText={t('changePassword.confirmation.actionBackToLogin')}
        buttonLink='/login'
        Icon={MailOutline}
      />
    )
  }

  return (
    <Stack component='form' onSubmit={handleForgotPassword} spacing={3}>
      <Header
        title={t('forgotpassword.title')}
        description={t('forgotpassword.description')}
      />

      <TextField
        margin='normal'
        required
        fullWidth
        id='email'
        label={t('login.emailInput.title')}
        name='email'
        autoComplete='email'
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
      />

      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        onClick={handleForgotPassword}
        loading={loading}
      >
        {t('forgotpassword.actionRequestPasswordReset')}
      </LoadingButton>

      <InfoLink href='/login'>
        {t('forgotpassword.toLogin')}
      </InfoLink>

    </Stack>
  )
}

export default ForgotPassword
