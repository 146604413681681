import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import InfoLink from '../../common/InfoLink'
import Header from '../../common/Header'
import useAuth from '../../hooks/useAuth'
import Validator from '../../common/validator'

const Login = () => {
  const { t } = useTranslation()
  const { login } = useAuth()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const clearValidation = useCallback(() => {
    setEmailError()
    setPasswordError()
  }, [setEmailError, setPasswordError])

  const validate = useCallback(() => {
    clearValidation()
    if (email && !Validator.email(email)) {
      setEmailError(t('login.emailInput.invalid'))
      return false
    }
    if (password && !Validator.userPassword(password)) {
      setPasswordError(t('login.passwordInput.invalidLength'))
      return false
    }
    return true
  }, [t, clearValidation, email, setEmailError, password, setPasswordError])

  useEffect(() => {
    clearValidation()
  }, [clearValidation, email, password])

  const handleLogin = useCallback(async (event) => {
    event.preventDefault()

    if (!validate()) {
      return false
    }

    setLoading(true)
    try {
      await login(email, password)
    } catch (error) {
      console.error(error)
      setEmailError(' ')
      setPasswordError(t(`backendError.${error.message}`))
    }
    setLoading(false)
  }, [t, validate, login, email, password, setPasswordError])

  return (
    <Stack component='form' onSubmit={handleLogin} spacing={3}>
      <Header
        title={t('login.title')}
        description={t('login.description')}
      />

      <TextField
        margin='normal'
        required
        fullWidth
        id='email'
        label={t('login.emailInput.title')}
        name='email'
        autoComplete='email'
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
      />

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='password' error={!!passwordError}>{t('login.passwordInput.title')}</InputLabel>
        <OutlinedInput
          id='password'
          aria-describedby='password-helper-text'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('login.passwordInput.title')}
          error={!!passwordError}
        />
        {passwordError && <FormHelperText error id='password-helper-text'>{passwordError}</FormHelperText>}
      </FormControl>

      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        loading={loading}
      >
        {t('login.actionLogin')}
      </LoadingButton>

      <InfoLink href='/forgotpassword'>
        {t('login.toPasswordReset')}
      </InfoLink>

    </Stack>
  )
}

export default Login
