import styled from 'styled-components'
import { Container, Paper } from '@mui/material'

import theme from '../../../../theme'
import Footer from '../../Layout/Footer'
import Version from '../../../common/VersionText'
import Background from './Background.png'

const LoginWrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-image: url(${Background});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const StyledContainer = styled(Container)`
  min-width: ${theme.spacing * 52}px;
  max-width: ${theme.spacing * 52}px;
  padding-top: ${theme.spacing * 3}px;
  padding-bottom: ${theme.spacing * 5}px;
`

const StyledPaper = styled(Paper)`
  border-radius: ${theme.spacing * 2}px !important;
`

const FixedVersion = styled(Version)`
  position: absolute;
  bottom: ${theme.spacing * 1}px;
  right: ${theme.spacing * 1}px;
`

const Page = ({ children, ...restProps }) => {
  return (
    <LoginWrapper>
      <StyledPaper elevation={2}>
        <StyledContainer maxWidth={false}>
          {children}
        </StyledContainer>
      </StyledPaper>
      <Footer />
      <FixedVersion />
    </LoginWrapper>
  )
}

export default Page
