import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import styled from 'styled-components'
import theme from '../../../../theme'
import useLanguage from '../../../hooks/useLanguage'
import useDocuments from '../../../hooks/useDocuments'

import Grid from '@mui/material/Grid'

import DocumentArtIcon from './ArtIcon.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const StyledGrid = styled.div`
  display: flex;
  align-items: center;
  &:hover div, &:hover span {
    color: ${theme.palette.primary.main};
  }
  &:hover {
    box-shadow: ${theme.shadow.cigarHover};
  }
  padding-left: ${theme.spacing * 3}px;
  padding-right: ${theme.spacing * 3}px;
  border-radius: 16px;
  box-shadow: ${theme.shadow.cigar};
  background-color: ${theme.palette.shade[0]};
  cursor: pointer;
  height: ${theme.spacing * 10}px;
`

const StyledTitle = styled(Grid)`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.p.fontSize};
  color: ${theme.palette.neutral[500]};
`

const StyledText = styled.span`
  color: ${theme.palette.primary[500]};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.pSmall.fontSize};
  text-decoration: none;
  position: relative;
  top: -3px;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  width: 24px !important;
  height: 24px !important;
  position: relative;
  top: 8px;
`

const StyledLinkItem = styled(Grid)`
  display: flex;
  align-items:center;
  justify-content: end;
`

const StyledIcon = styled.img.attrs({
  src: DocumentArtIcon
})`
  margin-right: ${theme.spacing * 3}px;
  height: 40px;
  width: 40px;
`

const DocumentArtItem = ({
  art,
  ...restProps
}) => {
  const { t } = useTranslation()
  const { linkWithLangParam, resolvedLanguage } = useLanguage()
  const { setContentReady } = useDocuments()

  const docartLink = id => linkWithLangParam(`/search?tags=${id}%2C`)

  const preventPrematureContentDisplay = () => setContentReady(false)

  return (
    <Grid
      item
      xs={6}
    >
      <Link href={docartLink(art.id)} onClick={preventPrematureContentDisplay}>
        <StyledGrid
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          <StyledIcon />
          <StyledTitle item xs={3}>{art[`title_${resolvedLanguage}`]}</StyledTitle>
          <Grid item xs={3} />
          <StyledLinkItem item xs={5}><StyledText>{t('dashboard.documentArtCount', { count: art.count })} <StyledArrowForwardIcon /></StyledText></StyledLinkItem>
        </StyledGrid>
      </Link>

    </Grid>
  )
}

export default DocumentArtItem
