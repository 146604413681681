import styled from 'styled-components'

import theme from '../../../theme'

const LinkToSection = ({ children, targetId, ...restProps }) => {
  const StyledLink = styled.a`
    color: ${theme.palette.primary[500]};
    &:hover {
      color: ${theme.palette.primary[500]};
    }
    &:visited {
      color: ${theme.palette.primary[500]};
    }
    &:link {
      color: ${theme.palette.primary[500]};
    }
    &:active {
      color: ${theme.palette.primary[500]};
    }
  `

  return (
    <StyledLink href={`#${targetId}`}>{children}</StyledLink>
  )
}

export default LinkToSection
