import styled from 'styled-components'
import { Link } from 'wouter'
import Typography from '@mui/material/Typography'
import theme from '../../theme'

const CenterTypography = styled(Typography)`
  text-align: center;
  padding-top: 4px;
  white-space: nowrap;
`

const StyledLink = (Element) => Element`
text-decoration: none;
text-transform: uppercase;
color: ${props => props.active ? theme.palette.primary[500] : theme.palette.neutral[400]} !important;
&:hover {
  color: ${theme.palette.primary.main} !important;
}
&:visited {
  color: ${theme.palette.neutral[400]};
}
&:link {
  color: ${theme.palette.neutral[400]};
}
&:active {
  color: ${theme.palette.primary[500]};
}
`

const MenuLink = ({
  href,
  target,
  children,
  active,
  ...restProps
}) => {
  const LinkElement = StyledLink((href.includes('://') || href.includes('mailto')) ? styled.a : styled(Link))

  return (
    <CenterTypography variant='pSmallSemiBold' {...restProps}>
      <LinkElement href={href} target={target} active={active}>
        {children}
      </LinkElement>
    </CenterTypography>
  )
}

export default MenuLink
