import { LanguageProvider } from './hooks/useLanguage'
import { AuthProvider } from './hooks/useAuth'
import { FavoritesProvider } from './hooks/useFavorites'
import { DocumentsProvider } from './hooks/useDocuments'
import { DashboardProvider } from './hooks/useDashboard'

const providers = [
  LanguageProvider,
  AuthProvider,
  FavoritesProvider,
  DocumentsProvider,
  DashboardProvider
]

const combineComponents = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

const AppContextProvider = combineComponents(providers)

export default AppContextProvider
