import LogoImageDe from './logo_de.svg'
import LogoImageEn from './logo_en.svg'
import useLanguage from '../../hooks/useLanguage'

const Logo = props => {
  const { language } = useLanguage()
  const logoSource = (language === 'de') ? LogoImageDe : LogoImageEn
  return (
    <img src={logoSource} alt='REA Sales Distributor Portal Logo' {...props} />
  )
}

export default Logo
