import styled from 'styled-components'

import theme from '../../../theme'

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${({ imagePosition }) => imagePosition === 'left' ? 'row' : 'row-reverse'};
    align-items: center;
    gap: ${theme.spacing * 3}px;
  `

const Anchor = styled.div`
    position: absolute;
    top: -${theme.spacing * 12}px;
  `

const Image = styled.img`
    width: 100%;
    height: auto;
  `

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
  `

const TextContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    gap: ${theme.spacing * 2}px;
  `

const Section = ({ children, id, imagePosition = 'left', imageSrc, imageAlt, ...restProps }) => {
  return (
    <Container imagePosition={imagePosition}>
      {/* Anchor is used to prevent content behind fixed header when navigating to this section */}
      <Anchor id={id} />
      {!!imageSrc && <ImageContainer><Image src={imageSrc} alt={imageAlt} /></ImageContainer>}
      <TextContainer>{children}</TextContainer>
    </Container>
  )
}

export default Section
