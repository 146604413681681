import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Redirect } from 'wouter'

import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import InfoLink from '../../common/InfoLink'

import Header from '../../common/Header'
import Result from '../../common/Result'

import useQueryParams from '../../hooks/useQueryParams'
import useAuth from '../../hooks/useAuth'
import Validator from '../../common/validator'

const ChangePassword = () => {
  const { t } = useTranslation()
  const { setNewPassword } = useAuth()

  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState()
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [passwordRepeatError, setPasswordRepeatError] = useState()
  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)

  const [queryParams] = useQueryParams()
  const { token } = queryParams

  const clearValidation = useCallback(() => {
    setPasswordError()
    setPasswordRepeatError()
  }, [setPasswordError, setPasswordRepeatError])

  const validate = useCallback(() => {
    clearValidation()
    if (!password || !Validator.userPassword(password)) {
      setPasswordError(t('changePassword.passwordInput.invalidLength'))
      return false
    }
    if (!passwordRepeat || (password !== passwordRepeat)) {
      setPasswordRepeatError(t('changePassword.passwordRepeatInput.invalid'))
      return false
    }
    return true
  }, [t, clearValidation, passwordRepeat, password, setPasswordRepeatError, setPasswordError])

  useEffect(() => {
    clearValidation()
  }, [clearValidation, password, passwordRepeat])

  const handleSetPassword = useCallback(async (event) => {
    event.preventDefault()

    if (!validate()) {
      return false
    }

    setLoading(true)
    try {
      await setNewPassword(password, token)
      setResult(true)
    } catch (error) {
      console.error(error)
      setResult(error.message)
    }
    setLoading(false)
  }, [validate, setNewPassword, password, token, setResult])

  const isFirstLogin = window.location.href.includes('/firstlogin')

  // error result
  if (result && result !== true) {
    return (
      <Result
        title={t(isFirstLogin ? 'changePassword.title' : 'changePassword.titleReset')}
        description={t('errorResult.description')}
        buttonText={t('errorResult.actionRetry')}
        buttonAction={() => setResult(false)}
        buttonSecondaryText={t('errorResult.actionBackToLogin')}
        buttonSecondaryLink='/login'
        Icon={ErrorOutlineIcon}
      />
    )
  }

  // success result
  if (result && result === true) {
    return (
      <Result
        title={t('changePassword.confirmation.title')}
        description={t('changePassword.confirmation.description')}
        buttonText={t('changePassword.confirmation.actionBackToLogin')}
        buttonLink='/login'
        Icon={CheckCircleOutlined}
      />
    )
  }

  if (!token) {
    return <Redirect to='/login' />
  }

  return (
    <Stack component='form' onSubmit={handleSetPassword} spacing={3}>
      <Header
        title={t(isFirstLogin ? 'changePassword.title' : 'changePassword.titleReset')}
        description={t('changePassword.description')}
      />

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='password' error={!!passwordError}>{t('changePassword.passwordInput.title')}</InputLabel>
        <OutlinedInput
          id='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('changePassword.passwordInput.title')}
          error={!!passwordError}
          required
        />
        {passwordError && <FormHelperText error id='password-helper-text'>{passwordError}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth variant='outlined'>
        <InputLabel required htmlFor='password-repeat' error={!!passwordRepeatError}>{t('changePassword.passwordRepeatInput.title')}</InputLabel>
        <OutlinedInput
          id='password-repeat'
          type={showPasswordRepeat ? 'text' : 'password'}
          value={passwordRepeat}
          onChange={e => setPasswordRepeat(e.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPasswordRepeat(prevValue => !prevValue)}
                onMouseDown={e => e.preventDefault()}
                edge='end'
              >
                {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          label={t('changePassword.passwordRepeatInput.title')}
          error={!!passwordRepeatError}
          required
        />
        {passwordRepeatError && <FormHelperText error id='password-repeat-helper-text'>{passwordRepeatError}</FormHelperText>}
      </FormControl>

      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        loading={loading}
      >
        {t('changePassword.setPasswordButton')}
      </LoadingButton>

      <InfoLink href='/login'>
        {t('changePassword.toLogin')}
      </InfoLink>

    </Stack>
  )
}

export default ChangePassword
