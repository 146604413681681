import { useTheme } from '@mui/material/styles'

import Typography from '@mui/material/Typography'

import packageInfo from '../../../package.json'

const VersionText = ({ ...restProps }) => {
  const theme = useTheme()
  return (
    <>
      <Typography color={theme.palette.neutral[50]} variant='footer' {...restProps}>{packageInfo.version}</Typography>
    </>
  )
}

export default VersionText
