import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import useAuth from './useAuth'
import Backend from '../common/Backend'

const FavoritesContext = createContext()

export const useFavorites = () => {
  const context = useContext(FavoritesContext)

  if (context === undefined) {
    throw new Error('useFavorites must be used within an FavoritesProvider')
  }

  return context
}

export const FavoritesProvider = ({ children }) => {
  const { session, loading: loadingSession } = useAuth()
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const loadFavorites = useCallback(async () => {
    if (!session?.user) {
      return
    }
    const favorites = await Backend.getFavorites(session.user.id, session.token)
    setFavorites(favorites)
  }, [session])

  const refresh = useCallback(async () => {
    setLoading(true)
    if (loadingSession) {
      return
    }
    setError('')
    try {
      await loadFavorites()
    } catch (error) {
      console.error('useFavorites', error)
      setError(error.message)
    }
    setLoading(false)
  }, [loadFavorites, loadingSession, setLoading, setError])

  useEffect(() => {
    refresh()
  }, [refresh])

  const add = useCallback(async documentId => {
    await Backend.addFavorite(session.user.id, documentId, session.token)
    await refresh()
  }, [refresh, session?.user?.id, session?.token])

  const remove = useCallback(async documentId => {
    const favorite = favorites.find(favorite => favorite.documentId === documentId)
    if (favorite) {
      await Backend.removeFavorite(session.user.id, favorite.id, session.token)
      await refresh()
    }
  }, [refresh, favorites, session?.user?.id, session?.token])

  const value = {
    favorites,
    loading,
    error,
    add,
    remove,
    refresh
  }

  return <FavoritesContext.Provider value={value}>{children}</FavoritesContext.Provider>
}

export default useFavorites
