import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import theme from '../../../theme'
import DocumentArtItem from './DocumentArtItem'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${theme.spacing * 4}px;
`

const Headline = styled(Typography).attrs({
  variant: 'h7'
})`
  color: ${theme.palette.primary.main};
`

const DocumentArtList = ({
  title,
  documentArts = [],
  ...restProps
}) => {
  return (
    <Container>
      {!!documentArts.length &&
        <ListHeader>
          <Headline>{title}</Headline>
        </ListHeader>}
      <Grid container spacing={3}>
        {documentArts.map(art => <DocumentArtItem key={art.id} art={art} />)}
      </Grid>
    </Container>
  )
}

export default DocumentArtList
