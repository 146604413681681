export const buildDownloadFilename = (doc, language) => {
  const category = buildCategoryString(doc.tags, language)
  const title = doc[`title_${language}`]
  if (category) {
    return `${category} - ${title}`
  }
  return `${title}`
}

export const buildCategoryString = (tags, language) => {
  const productsString = tags.filter(tag => tag.type === 'products').map(tag => tag[`title_${language}`]).join(', ')
  if (productsString) {
    return productsString
  }
  const themesString = tags.filter(tag => tag.type === 'themes').map(tag => tag[`title_${language}`]).join(', ')
  return themesString
}
