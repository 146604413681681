// color definitions
const primary50 = '#cae1f9'
const primary50Transparent = 'rgba(202, 225, 249, 0.5)'
const primary300 = '#1769bb'
const primary500 = '#0453a2'
const primary700 = '#036'
const primary900 = '#032343'
const neutral50 = '#f8fafc'
const neutral100 = '#f1f5f9'
const neutral200 = '#e2e8f0'
const neutral300 = '#cbd5e1'
const neutral400 = '#94a3b8'
const neutral500 = '#64748b'
const neutral600 = '#475569'
const neutral700 = '#334155'
const neutral800 = '#1e293b'
const neutral900 = '#0f172a'
const shade0 = '#fff'
const shade0Transparent = 'rgba(255, 255, 255, 0.6)'
const shade0Transparent065 = 'rgba(255, 255, 255, 0.65)'
const shade0Transparent085 = 'rgba(255, 255, 255, 0.85)'
const shade100 = '#000'
const shade100Transparent = 'rgba(0, 0, 0, 0.1)'
const accent700 = '#16c3bc'
const error700 = '#a00'
const backgroundlight = '#fbfbfb'
const chip = 'rgba(4, 83, 162, 0.8)'

// font definition
const fontFamily = 'Open Sans'
const fontWeightRegular = 400
const fontWeightSemiBold = 600
const fontWeightBold = 700

// theme definition
const Theme = {
  spacing: 8,
  maxContentWidth: '1300px',
  typography: {
    fontFamily,
    h1: {
      fontSize: '57.3px',
      fontWeight: fontWeightSemiBold
    },
    h2: {
      fontSize: '47.8px',
      fontWeight: fontWeightSemiBold
    },
    h3: {
      fontSize: '39.8px',
      fontWeight: fontWeightSemiBold
    },
    h4: {
      fontSize: '33.2px',
      fontWeight: fontWeightSemiBold
    },
    h5: {
      fontSize: '27.1px',
      fontWeight: fontWeightSemiBold
    },
    h6: {
      fontSize: '23px',
      fontWeight: fontWeightBold
    },
    h7: {
      fontSize: '19.2px',
      fontWeight: fontWeightBold
    },
    pLargeSemiBold: {
      fontSize: '19.2px',
      fontWeight: fontWeightSemiBold
    },
    pLarge: {
      fontSize: '19.2px',
      fontWeight: fontWeightRegular
    },
    pSemiBold: {
      fontSize: '16px',
      fontWeight: fontWeightSemiBold
    },
    p: {
      fontSize: '16px',
      fontWeight: fontWeightRegular
    },
    pSmallSemiBold: {
      fontSize: '13.3px',
      fontWeight: fontWeightSemiBold
    },
    pSmall: {
      fontSize: '13.3px',
      fontWeight: fontWeightRegular
    },
    captionSemiBold: {
      fontSize: '11.1px',
      fontWeight: fontWeightSemiBold
    },
    caption: {
      fontSize: '11.1px',
      fontWeight: fontWeightRegular
    },
    footerSemiBold: {
      fontSize: '9.3px',
      fontWeight: fontWeightSemiBold
    },
    footer: {
      fontSize: '9.3px',
      fontWeight: fontWeightRegular
    },
    body1: {
      fontSize: '16px',
      fontWeight: fontWeightRegular
    },
    body2: {
      fontSize: '13.3px',
      fontWeight: fontWeightRegular
    },
    button: {
      fontSize: '14px',
      fontWeight: fontWeightRegular
    },
    iconLarge: {
      fontSize: '60px'
    }
    // overline: 'px'
  },
  palette: {
    type: 'light',
    primary: {
      main: primary700,
      50: primary50,
      '50Transparent': primary50Transparent,
      300: primary300,
      500: primary500,
      900: primary900
    },
    neutral: {
      main: neutral700,
      50: neutral50,
      100: neutral100,
      200: neutral200,
      300: neutral300,
      400: neutral400,
      500: neutral500,
      600: neutral600,
      700: neutral700,
      800: neutral800,
      900: neutral900
    },
    shade: {
      0: shade0,
      '0Transparent': shade0Transparent,
      '0Transparent065': shade0Transparent065,
      '0Transparent085': shade0Transparent085,
      100: shade100,
      '100Transparent': shade100Transparent
    },
    accent: {
      main: accent700
    },
    error: {
      main: error700
    },
    background: {
      light: backgroundlight
    },
    secondary: {
      main: neutral600
    },
    icon: {
      main: primary700,
      light: neutral400
    },
    chip: {
      main: chip
    }
  },
  shadow: {
    main: '0 4px 30px 0 rgba(0, 0, 0, 0.1)',
    chip: '0 8px 16px 0 rgba(0, 0, 0, 0.05)',
    chipHover: '0 8px 16px 0 rgba(0, 79, 142, 0.1)',
    cigar: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
    cigarHover: '0 0 10px 0 rgba(0, 81, 138, 0.2)',
    card: '0 10px 10px 0 rgba(0, 0, 0, 0.2)',
    cardHover: '0 0px 10px 0 rgba(0, 81, 138, 0.2)'
  },
  button: {
    navbar: {
      height: '36',
      width: '105'
    }
  }
}

export default Theme
